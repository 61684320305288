import { FunctionalComponent, h } from "preact";

const Finish: FunctionalComponent = () => {
  return (
    <div>
      <h1>Finish</h1>
    </div>
  );
};

export default Finish;
