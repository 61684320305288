import { animated, useSprings } from "@react-spring/web";
import { Fragment, FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  rightsToPublicDomainAtom,
  signLaguageSelectionAtom,
  signLanguageList,
  termsAndConditionsAtom,
  webLanguageSelector,
} from "../../../recoil/global";
import {
  PrimaryButtonStyle,
  fluidFont,
  Title,
  SecondaryButtonStyle,
  QuoteBlock,
} from "../../../style/globalStyle";
import RightsToPublicDomain from "./rightsToPublicDomain";
import TermsAndConditionsContent from "./termsAndConditionsContent";
import accessNestedObj from "../../../utils/object";
import { ARROW_UP_RIGHT_FROM_SQUARE } from "../../../routes/review/icon_svg";

type PageProps = {
  page: number;
  pageState: number;
};

const PAGE_PATH: string = "collect.consent";
const TERMS_AND_CONDITIONS: string = "terms-and-conditions-and-privacy-policy";
const RIGHTS_TO_PUBLIC_DOMAIN: string = "rights-to-public-domain";

export const Page = styled.section<PageProps>`
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s;
  transform: translate(
    ${(props) => props.page * 100 - props.pageState * 100 + "%"},
    0
  );
  overflow: hidden;
`;
export const Content = styled.section`
  height: 100%;
  width: 100%;
  max-width: 720px;
  margin: auto;
  overflow: auto;
`;
export const ButtonWrapper = styled.section`
  width: 100%;
  max-width: 720px;

  position: fixed;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #f9f9f9;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.07);
  height: auto;
  padding: 1rem;
`;

export const ButtonWrapperRow = styled.section`
  width: 100%;
  max-width: 720px;

  position: fixed;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #f9f9f9;
  opacity: 1;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.07);
  height: auto;

  display: flex;
  flex-direction: row;

  padding: 1.5rem 1rem;
  z-index: 10;

  button {
    margin: 0 0.5rem;
  }
`;

const IagreeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;

  p {
    margin: 0.6rem;
    ${fluidFont(12, 16)}
    cursor: pointer;
  }
`;

type checkT = {
  checked: boolean;
};
const CheckboxCustom = styled.span<checkT>`
  width: 1.2rem;
  height: 1.2rem;
  span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 100%;
    height: 100%;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: ${(props) =>
      props.checked ? `#9BD9F4 -19px top no-repeat` : `left top no-repeat`};
    cursor: pointer;
    margin-right: 4px;

    border: ${(props) =>
      props.checked ? `1px solid #9BD9F4` : `1px solid #80868b`};
    box-sizing: border-box;

    border-radius: 15%;
  }
`;

const VideoWrapper = styled.section`
  width: 80%;
  height: 50%;
  display: flex;
  margin: 1rem auto;
  border: 4px solid #333333;
  box-sizing: border-box;
  border-radius: 6px;
`;
const VideoStyle = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;
const VideoLink = styled.a`
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  ${fluidFont(12, 16)}
  line-height: 100%;
  /* identical to box height, or 16px */

  text-align: center;

  /* Gray 1 */

  // color: #333333;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-block-start: 1em;
  margin-block-end: 1em;
`;

type pageAnimateDivWrapperProps = {
  page: number;
};
const PageAnimateDivWrapper = styled.div<pageAnimateDivWrapperProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const CheckIcon = (
  <svg
    stroke="#f9f9f9"
    fill="#f9f9f9"
    stroke-width="0"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
  </svg>
);

enum pageStateE {
  termsAndCondition,
  rightsToPublicDomain,
}

const TermsAndConditions: FunctionalComponent = () => {
  const signLanguageSelection = useRecoilValue(signLaguageSelectionAtom);
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);

  const [, updateTermsAndConditions] = useRecoilState(termsAndConditionsAtom);
  const [, updateRightsToPublicDomain] = useRecoilState(
    rightsToPublicDomainAtom
  );
  const [pageState, updatePageState] = useState<pageStateE>(
    pageStateE.termsAndCondition
  );
  const [isCheck, setIsCheck] = useState(false);

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );

  const handleSubmitTermsAndConditions = () => {
    updateTermsAndConditions(true);
    updatePageState(pageStateE.rightsToPublicDomain);
    goTo(1);
  };

  const handleClickRightsToPublicDomain = (input: number) => {
    updateRightsToPublicDomain(input);
    route("/collect/choose-session");
  };

  const pages = [
    <Page page={0} pageState={pageState}>
      <Content>
        <Title>{language[TERMS_AND_CONDITIONS].title}</Title>
        <VideoWrapper>
          <VideoStyle
            src={`../../assets/videos/consent/${signLanguageList[signLanguageSelection].key}/termsAndConditions.mp4`}
            autoPlay
            playsInline
            loop
            muted
            controls
          />
        </VideoWrapper>
        {signLanguageList[signLanguageSelection].key === "JSL" && (
          <VideoLink
            href={`../../assets/videos/consent/${signLanguageList[signLanguageSelection].key}/long/termsAndConditions.mp4`}
            target="_blank"
          >
            {language[TERMS_AND_CONDITIONS].longVideoLink}
          </VideoLink>
        )}
        {language[TERMS_AND_CONDITIONS].quoteBlock !== "" && (
          <QuoteBlock>{language[TERMS_AND_CONDITIONS].quoteBlock}</QuoteBlock>
        )}
        <TermsAndConditionsContent />
      </Content>
      <ButtonWrapper>
        <IagreeWrapper>
          <CheckboxCustom
            onClick={() => setIsCheck(!isCheck)}
            checked={isCheck}
          >
            <span>{isCheck && CheckIcon}</span>
          </CheckboxCustom>
          <p onClick={() => setIsCheck(!isCheck)}>
            {language[TERMS_AND_CONDITIONS].checkboxText}
          </p>
        </IagreeWrapper>
        <PrimaryButtonStyle
          onClick={handleSubmitTermsAndConditions}
          disabled={!isCheck}
        >
          {language[TERMS_AND_CONDITIONS].buttonText}
        </PrimaryButtonStyle>
      </ButtonWrapper>
    </Page>,
    <Page page={1} pageState={pageState}>
      <Content>
        <Title>{language[RIGHTS_TO_PUBLIC_DOMAIN].title}</Title>
        <VideoWrapper>
          <VideoStyle
            src={`../../assets/videos/consent/${signLanguageList[signLanguageSelection].key}/rightsToPublicDomain.mp4`}
            autoPlay
            playsInline
            loop
            muted
            controls
          />
        </VideoWrapper>
        {signLanguageList[signLanguageSelection].key === "JSL" && (
          <VideoLink
            href={`../../assets/videos/consent/${signLanguageList[signLanguageSelection].key}/long/rightsToPublicDomain.mp4`}
            target="_blank"
          >
            {language[RIGHTS_TO_PUBLIC_DOMAIN].longVideoLink}
          </VideoLink>
        )}
        <RightsToPublicDomain />
      </Content>
      <ButtonWrapperRow>
        <SecondaryButtonStyle
          onClick={() => handleClickRightsToPublicDomain(1)}
        >
          {language[RIGHTS_TO_PUBLIC_DOMAIN].rejectButtonText}
        </SecondaryButtonStyle>
        <PrimaryButtonStyle onClick={() => handleClickRightsToPublicDomain(2)}>
          {language[RIGHTS_TO_PUBLIC_DOMAIN].acceptButtonText}
        </PrimaryButtonStyle>
      </ButtonWrapperRow>
    </Page>,
  ];

  const [springs, api] = useSprings(pages.length, (index) => ({
    xs: index * 120,
  }));

  function goTo(page: number, immediate: boolean = false) {
    api.start((index) => ({
      xs: (index - page) * 120, // Transition page based on given index
      immediate,
    }));
  }

  return (
    <Fragment>
      {/* {springs.map(({ xs }, idx) => {
        return (
          <animated.div
            key={idx}
            style={{
              transform: xs.to((x) => `translate(${x}%, 0)`),
              overflow: "hidden",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            {pages[idx]}
          </animated.div>
        );
      })} */}
      <PageAnimateDivWrapper page={pageState}>
        {pages.map((page, idx) => pages[idx])}
      </PageAnimateDivWrapper>
      {/* {pageState === pageStateE.termsAndCondition && (
        <ButtonWrapper>
          <IagreeWrapper>
            <CheckboxCustom onClick={() => setIsCheck(!isCheck)} checked={isCheck}>
              <span>{isCheck && CheckIcon}</span>
            </CheckboxCustom>
            <p onClick={() => setIsCheck(!isCheck)}>
              I agree to the Terms of Service and Privacy Policy
            </p>
          </IagreeWrapper>
          <PrimaryButtonStyle onClick={handleSubmitTermsAndConditions} disabled={!isCheck}>
            Submit
          </PrimaryButtonStyle>
        </ButtonWrapper>
      )} */}
      {/* {pageState === pageStateE.rightsToPublicDomain && (
        <ButtonWrapperRow>
          <SecondaryButtonStyle onClick={() => handleClickRightsToPublicDomain(1)}>
            No, thanks
          </SecondaryButtonStyle>
          <PrimaryButtonStyle onClick={() => handleClickRightsToPublicDomain(2)}>
            Yes, please
          </PrimaryButtonStyle>
        </ButtonWrapperRow>
      )} */}
    </Fragment>
  );
};

export default TermsAndConditions;
