import { h } from "preact";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { webLanguage, webLanguageAtom } from "../recoil/global";

const SelectStyle = styled.select`
  position: absolute;
  right: 5%;
  top: 5%;
  z-index: 9;
`;

interface language {
  name: string;
  value: string;
}

const LANGUAGES: language[] = [
  { name: "English", value: webLanguage.EN },
  { name: "日本語", value: webLanguage.JP },
];

const DropDownLanguages = () => {
  const [, setWebLanguage] = useRecoilState(webLanguageAtom);

  const onLanguageChange = (event: any): void => {
    const { value } = event.target;
    setWebLanguage(value);
  };

  const dropdown = LANGUAGES.map((data) => (
    <option value={data.value}>{data.name}</option>
  ));

  return <SelectStyle onChange={onLanguageChange}>{dropdown}</SelectStyle>;
};

export default DropDownLanguages;
