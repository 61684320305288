const japaneseMapping = {
  collect: {
    chooseSignLanguage: {
      title: "言語を選んでください。",
      signLanguage: {
        HKSL: "香港手話",
        JSL: "日本手話",
        YSL: "ヤンゴン手話",
      },
    },
    consent: {
      "terms-and-conditions-and-privacy-policy": {
        title: "利用規約",
        longVideoLink: "利用規約（日本手話版）",
        checkboxText: "利用規約に同意します。",
        buttonText: "送信",
        quoteBlock:
          "* 本利用契約は、英語で作成され、日本語・日本手話に翻訳される。英文版が正本であり、日本語版・日本手話版は参考として作成される。これら両言語版の間に矛盾抵触がある場合、英文版が優先する。",
        paragraph: {
          "terms-and-condition": {
            title: "● ご利用規約とプライバシーポリシー",
            text: (url: string) =>
              japaneseConsent.termsAndConditionJapaneseMapping(url),
          },
          "user-content": {
            title: "● ユーザーのコンテンツ",
            text: "当社は、お客様が本サービス利用の一環として本ウェブサイトに送信したデータ、情報、または素材（以下「コンテンツ」）に対する所有権を有しません。お客様は、送信された全コンテンツの正確性、品質、完全性、合法性、信頼性、適切性、知的財産の所有権、または使用権について、単独で責任を負うものとします。当社は、お客様が当社のサービスを利用して送信または作成した、本ウェブサイト上のコンテンツを監視および確認することがありますが、その義務を負うものではありません。お客様は当社がお客様に対するサービス提供に必要である場合に限り、お客様のユーザー アカウントのコンテンツに対するアクセス、およびコンテンツのコピー、配信、保存、送信、再フォーマット、表示、実行を当社に許可するものとします。コンテンツが当社のポリシーに違反しているか、有害または不適切であると当社が合理的に判断した場合には、当社は上記の表明または保証を制限することなく、独自の裁量により、当該コンテンツを却下または削除する権利を有します。ただしその義務を負うものではありません。お客様は当社に対し、お客様が営利目的、マーケティング用途、またはそれに類する目的でユーザー アカウント内で作成したか保存したコンテンツの使用、再現、適応、変更、公開、または配信を許諾するものとします。",
          },
          backups: {
            title: "● バックアップ",
            text: "当社は本ウェブサイトおよびコンテンツの定期的なバックアップを実施し、そのバックアップの完全性と正確性を確保するために最善を尽くします。ハードウェア障害やデータ損失が発生した場合、当社はバックアップを自動的に復元し、影響とダウンタイムの最小化を図ります。",
          },
          "links-to-other-websites": {
            title: "● 他のウェブサイトへのリンク",
            text: "本ウェブサイトが他のウェブサイトにリンクされている場合でも、本利用規約に特に明記されていない限り、当社はリンクされているウェブサイトについての承認、関連性、スポンサーシップ、支持、または提携関係を直接的または間接的に示唆するものではありません。当社はどの企業または個人、あるいはそのウェブサイトのコンテンツが提供するサービスについて調査または評価する責任を負うものではなく、また保証するものではありません。当社は、第三者による行為、プロダクト、サービス、コンテンツについて、いかなる責任または法的責任も負いません。お客様は、本ウェブサイト内のリンクからアクセスできるウェブサイトについて、法的な声明やその他の使用条件を慎重に検討する必要があります。他のオフサイトのウェブサイトへのリンクは、お客様の責任のもとで行われるものとします。",
          },
          "limitation-of-liability": {
            title: "● 責任の制限",
            text: "適用法で許容された最大の範囲で、ウェブサイト運営者および関係会社、その取締役、役員、従業員、代理人、サプライヤー、またはライセンサーは、間接的損害、偶発的損害、特別損害、懲罰的損害、または対象範囲の結果的損害（利益、収益、売上、信用の毀損、コンテンツの使用、ビジネスに対する影響、ビジネスの中断、予想された節減の逸失、ビジネス チャンスの逸失を含むがこれに限定されない）に対して、契約、不法行為、保証、法定義務違反、過失を含むがこれに限定されないいかなる責任法理のもとでも、またそのような損害の可能性について助言を受けているか、そうした損害を予見し得た場合であっても、理由を問わず責任を負わないものとします。適用法によって許容される最大の範囲内で、サービスに関する本ウェブサイト運営者および関係会社、役員、従業員、代理人、サプライヤー、ライセンサーの合計補償額は、1 ドル、またはそのような責任が最初に発生した事象に先立つ 1 か月間にお客様から本ウェブサイト運営者に現金で支払われた実際の金額のうち、いずれか高いほうの金額に制限されるものとします。この制限と除外は、この救済によってお客様の損失や本質的な目的の不達成が完全に補償されない場合にも適用されます。",
          },
          "changes-and-amendments": {
            title: "● 変更および修正",
            text: "当社は、本契約、あるいはウェブサイトまたはサービスに関するポリシーをいつでも変更する権利を留保します。この変更は、本契約の更新版を本ウェブサイトに投稿した時点で効力を生ずるものとします。更新版が投稿されると、このページの最下部にある日付が更新されます。お客様が更新後に本ウェブサイトの使用を続行した場合には、その更新に同意したものとみなされます。",
          },
          "acceptance-of-these-terms": {
            title: "● 本利用規約への同意",
            text: "お客様は本契約書を読み、すべての利用規約に同意するものとします。お客様は本ウェブサイトまたはそのサービスを使用することで、本契約の条件に拘束されることに同意したことになります。本契約の条件を遵守することに同意しない場合、お客様は本ウェブサイトおよびそのサービスの使用またはアクセスを許可されません。",
          },
          "contacting-us": {
            title: "● お問い合わせ",
            text: "本契約の詳細または関連する事項について当社にお問い合わせいただく場合は、contact@bit.studio までメールをお送りください。",
          },
          "last-updated": "本文書の最終更新日: 2020 年 7 月 17 日",
          "privacy-policies": {
            title: "● プライバシー ポリシー",
            text: `本プライバシー ポリシー（以下「ポリシー」）は、お客様（以下「ユーザー」、「お客様」）が bit-sign-lang-data-collector.web.app ウェブサイトおよびそのプロダクトまたはサービス（総称して「ウェブサイト」または「サービス」）に提供した個人を特定できる情報（以下「個人情報」）を、本ウェブサイトの運営者（以下「ウェブサイト運営者」、「当社」）が収集、保護、使用する方法を記述したものです。
            
            また本ポリシーは、当社によるお客様の個人情報の使用方法と、お客様による個人情報へのアクセスおよびその更新方法について、お客様が選択できる事項を示しています。本ポリシーは、当社が所有または管理していない企業の事業、または当社が雇用または管理していない個人には適用されません。`,
          },
          "automatic-collection-of-information": {
            title: "● 情報の自動収集",
            text: "当社は顧客データのセキュリティを最優先とするため、ログを作成しないポリシーをとっています。当社は本ウェブサイトまたはサービスの維持に絶対に必要な範囲を限度として、最小限のユーザーデータを取り扱います。自動的に収集された情報は、不正使用の疑いを特定し、またウェブサイトの利用状況に関する統計情報を確立する目的でのみ使用されます。この統計情報は、それらの目的以外の用途でシステムの特定のユーザーを識別するために集約されることはありません。",
          },
          "collection-of-personal-information": {
            title: "● 個人情報の収集",
            text: `お客様は、身元や個人を特定できるような情報を開示せずに、本ウェブサイトにアクセスできます。ただし本ウェブサイトの一部の機能については、特定の個人情報（氏名、メールアドレスなど）の提供を求められる場合があります。当社は、お客様がコンテンツを公開する際に、または本ウェブサイトのオンライン フォームに入力する際に当社に意図的に提供した情報を受け取り、保存します。必要な場合には、この情報には記事、画像、フィードバックなど、お客様が当社に積極的に送信した素材が含まれます。

            お客様は当社に個人情報を提供しないようにすることもできますが、その場合には本ウェブサイトの一部の機能を使用できないことがあります。提供する必要がある情報について不明点がある場合は、当社までお問い合わせください。
            `,
          },
          "use-and-processing-of-collected-information": {
            title: "● 収集した情報の使用と処理",
            text: `本ウェブサイトとサービスを利用していただくため、また法的義務を果たすために、当社は特定の個人情報を収集し、使用する必要があります。当社が求める情報をお客様が提供しない場合には、お客様が要求するプロダクトまたはサービスを提供できないことがあります。情報によっては、当社が本ウェブサイトを通じてお客様から直接収集する場合があります。一方で、お客様の個人情報を他のソースから収集する可能性もあります。お客様から収集した情報は、以下の目的で使用される場合があります。
            <ul>
              <li>手話を検出する機械学習モデルのトレーニング</li>
              <li>ユーザー エクスペリエンスの向上</li>
              <li>当社のウェブサイトとサービスの実施および運営</li>
            </ul>
            お客様の個人情報の処理は、お客様による本ウェブサイトへのアクセス方法、お客様の所在地、および次の条件が該当するかどうかに応じて異なります。（i）1 つ以上の具体的な目的について同意していること。ただしこれは個人情報の処理が欧州のデータ保護法の対象になる場合には適用されません。（ii）お客様との契約の履行または契約外の義務の履行に情報の提供が必須である場合。（iii）お客様の法的義務の遵守のために個人情報の処理が必須である場合。（iv）個人情報の処理が公共の利益にかかわるか、当社の職務上の権限の行使に必要である場合。（v）当社または第三者の正当な利益の追求に個人情報の処理が必要である場合。

            法律の種類によっては、お客様が（オプトアウトによって）異議を表明するまでは、お客様による同意や法的根拠がなくても当社が個人情報を処理できる場合があります。いずれの場合も、当社は個人情報の処理に関する法的根拠、特に個人情報の提供が法的なまたは契約上の要件であるか、あるいは契約の締結に必要であるかなどをいつでも明確にする用意があります。
            `,
          },
          "disclosure-of-information": {
            title: "● 情報の開示",
            text: `要求されたサービスの種類によっては、あるいは取引の完了またはお客様がリクエストしたサービスの提供に必要であるかに応じて、当社はお客様の同意のもとで、業務に関係する信頼できる第三者、あるいはお客様に提供する本ウェブサイトおよびサービスの運営をサポートするその他の関係会社や子会社と個人情報を共有する場合があります。当社は、提携関係にない第三者と個人情報を共有することはありません。これらのサービス プロバイダには、当社の代理としてサービスを提供するため、または法的要件を遵守するために必要な場合を除き、お客様の個人情報を使用または開示する権限はありません。当社は、第三者のプライバシー ポリシーが当社のポリシーと一致しているか、第三者が個人情報について当社のポリシーを遵守することに同意していない限り、上記の目的でお客様の個人情報をその第三者と共有することはありません。これらの第三者には、指定された職務を遂行するために必要な範囲に限り、個人情報が提供されます。当社がこれら第三者に対し、第三者自身のマーケティングあるいはその他の目的のために個人情報を使用または開示する権限を付与することはありません。

            当社は、召喚状に従うため、または類似する法的手続きのためなど、法律によって要求または許可されている場合、さらには当社の権利の保護、お客様の安全保護、他者の安全保護、不正行為の調査、または政府機関による要請への対応のために個人情報の開示が必要であると善意で判断する場合には、収集した個人情報を開示、使用、または受領します。
            `,
          },
          "retention-of-information": {
            title: "● 個人情報の保持",
            text: "当社は、保持期間の延長が必要であるか、法律によって延長が許可されている場合を除き、当社の法的義務の遵守、紛争の解決、契約の履行に必要な期間に限り、お客様の個人情報を保持し使用します。当社は、お客様が個人情報を更新または削除した後でも、お客様の個人情報から集計したデータを利用する場合がありますが、お客様個人が特定される方法で利用することはありません。保持期間が終了した時点で、個人情報は削除されるものとします。そのため、保持期間の終了後に、データに対するアクセス、消去、訂正、移植の権利を行使することはできません。",
          },
          "the-rights-of-users": {
            title: "● ユーザーの権利",
            text: "お客様は当社が処理したお客様の個人情報について、特定の権利を行使することができます。特に、お客様は以下のことを行う権利を有します。（i）お客様の個人情報の処理について当社に与えた同意を取り消すこと。（ii）同意した範囲外の法的根拠に基づくお客様の個人情報の処理について異議を表明すること。（iii）お客様の個人情報が当社によって処理されているかを確認し、処理の特定の局面に関する情報開示を求め、進行中の処理に関する情報のコピーを取得すること。（iv）お客様の個人情報の正確性を確認し、更新または訂正を依頼すること。（v）特定の状況において、お客様の個人情報の処理を制限すること。その場合当社は、情報の保存以外の目的でお客様の個人情報を処理することはありません。（vi）特定の状況において、当社に対して個人情報の消去を求めること。（vii）構造化された、コンピュータが解読可能な一般的な形式でお客様の個人情報を受け取り、また技術的に可能であれば、その情報が別の管理者に支障なく送信されるようにすること。本条項は、お客様の個人情報が自動的な手法で処理され、その処理がお客様の同意、お客様が当事者となっている契約、または契約外の義務に基づいて行われる場合に適用されます。",
          },
          "privacy-of-children": {
            title: "● 未成年のプライバシー",
            text: "当社は、18 歳未満の未成年の個人情報を意図的に収集することはありません。お客様が 18 歳未満である場合は、当社のウェブサイトまたはサービスを通じて個人情報を送信しないようにご注意ください。保護者および法定後見人の方は、お子様のインターネットの利用状況を監視し、当社のウェブサイトまたはサービスを通じて許可なく個人情報を提供しないようにお子様を指導することで、本ポリシーの施行にご協力ください。18 歳未満の未成年が当社のウェブサイトまたはサービスを通じて当社に個人情報を提供したと思われる場合は、当社までご連絡ください。また未成年である場合、お住まいの国におけるお客様の個人情報の処理に同意することはできません（一部の国では、保護者または法定後見人が未成年本人に代わって同意できる場合があります）。",
          },
          "do-not-track-signals": {
            title: "● トラッキング拒否シグナル",
            text: "一部のブラウザには、アクセスしたウェブサイトでお客様のオンライン アクティビティがトラッキングされないように指定できる、トラッキング拒否機能が組み込まれています。トラッキングは、ウェブサイトに関連する情報を利用または収集する操作とは異なります。この目的でのトラッキングとは、異なるウェブサイト間を移動しながら特定のウェブサイトまたはオンライン サービスにアクセスする消費者について、個人を特定できる情報を収集することを意味します。当社のウェブサイトは、第三者のウェブサイトへのアクセスを含め、訪問者を継続的にトラッキングすることはありません。ただし一部の第三者のサイトでは、お客様にコンテンツを配信する際にお客様の閲覧行動を追跡し、提供する情報を調整する場合があります。",
          },
          "links-to-other-websites-2": {
            title: "● 他のウェブサイトへのリンク",
            text: "当社のウェブサイトには、当社が所有または管理しない他のウェブサイトへのリンクが含まれています。当社はそうした他のウェブサイトまたは第三者のプライバシー慣行に対する責任を負わないため、ご注意ください。当社のウェブサイトを離れる際に注意を払い、また個人情報を収集する各ウェブサイトのプライバシーに関する声明を確認することをおすすめします。",
          },
          "information-security": {
            title: "● 情報の保護",
            text: "当社はお客様がコンピュータ サーバーに提供した情報を管理された安全な環境に保存し、不正なアクセス、使用、または開示から保護します。当社は、不正なアクセス、使用、変更、開示から個人情報を保護するために、合理的な範囲で管理的、技術的、物理的な保護を維持します。ただし、インターネットまたはワイヤレス ネットワークを通じたデータ送信は保証されません。したがって、当社はお客様の個人情報の保護に努めますが、（i）インターネットには当社の管理が及ばないセキュリティ上およびプライバシー上の限界があること、（ii）お客様と当社のウェブサイト間で交換される情報およびデータのセキュリティ、完全性、プライバシーを完全に保証することはできないこと、（iii）それらの情報およびデータの送信中に、第三者によって表示または改ざんされる可能性があることを了解ください。",
          },
          "data-breach": {
            title: "● データ侵害",
            text: "セキュリティ攻撃や不正行為を含むがこれに限定されない外部要因によって、本ウェブサイトのセキュリティが侵害されたか、ユーザーの個人情報が無関係の第三者に開示されたことを当社が認識した場合、当社は調査および報告、ならびに法執行機関への通報と協力を含むがこれに限定されない、合理的かつ適切な対策をとる権利を留保します。データ侵害が発生した場合、当社は侵害の結果としてユーザーに危険が及ぶ可能性について、法律によって通知が義務付けられている場合を含め、影響を受ける個人に通知するための合理的な努力を払います。この通知は、本ウェブサイトに通知を掲示することによって行います。",
          },
          "changes-and-amendments-2": {
            title: "● 変更および修正",
            text: "当社は独自の裁量によって本プライバシー ポリシーを随時更新する場合があります。当社による個人情報の取り扱いについて重大な変更があった場合にはお客様に通知します。更新があった場合には、このページの最下部にある日付が更新されます。また、当社独自の裁量により、お客様から提供された連絡先に通知するなど、他の方法で通知する場合があります。本プライバシー ポリシーの更新版は、別段の定めがない限り、改訂されたプライバシー ポリシーが投稿された時点で直ちに発効するものとします。改訂されたプライバシー ポリシーの発効日以後もお客様が本ウェブサイトまたはサービスの利用を続行した場合（またはその時点で定められている他の行為を行った場合）、お客様はポリシーの変更に同意したものとみなされます。ただし、当社はお客様の同意なく、お客様の個人情報が収集された時点で表明されていた方法と著しく異なる方法で、お客様の個人情報を使用することはありません。",
          },
          "acceptance-of-this-policy": {
            title: "● 本プライバシー ポリシーへの同意",
            text: "お客様は本プライバシー ポリシーを読み、すべての利用規約に同意するものとします。お客様は本ウェブサイトまたはそのサービスを使用することで、本プライバシー ポリシーの条件に拘束されることに同意したことになります。本プライバシー ポリシーの条件を遵守することに同意しない場合、お客様は本ウェブサイトおよびそのサービスの使用またはアクセスを許可されません。",
          },
          "contacting-us-2": {
            title: "● お問い合わせ",
            text: "本プライバシー ポリシーの詳細または個人の権利および個人情報に関連する事項について当社にお問い合わせいただく場合は、contact@bit.studio までメールをお送りください。",
          },
        },
      },
      "rights-to-public-domain": {
        title: "パブリック ドメインへの権利提供",
        longVideoLink: "パブリックドメイン",
        rejectButtonText: "許可しません。",
        acceptButtonText: "許可します。",
        paragraph: {
          "dedication-of-rights-to-the-public-domain": {
            title: "● パブリック ドメインへの権利提供",
            text: (url: string) =>
              japaneseConsent.rightsToPublicDomainJapaneseMapping(url),
          },
          "requirements-and-registration": {
            title: "● 要件と登録",
            text: "お客様は手話データ収集ツールと拘束力のある契約を締結し、本利用規約および適用されるすべての法律、規則、規制を遵守する場合のみ、本サービスを使用できます。本サービスの特定の機能にアクセスして使用するには、登録が必要な場合があります。本サービスに登録することで、お客様は本サービスの登録フォームで要求される、真正かつ正確で最新の情報を提供することに同意したとみなされます。登録データおよびお客様に関するその他の情報は、当社のプライバシー ポリシーに沿って取り扱われます。お客様が 16 歳未満の場合、お客様は保護者、後見人、または教師の承認の下でのみ本サービスを使用できます。",
          },
          "uploading-content": {
            title: "● コンテンツのアップロード",
            text: `本ウェブサイトにコンテンツをアップロードすることで、お客様は手話データ収集ツール、および当社のライセンサー、関係会社、およびパートナーに対し、<a href="https://creativecommons.org/publicdomain/zero/1.0/">CC0 1.0 Universal（CC0 1.0）Public Domain Dedication</a> の下でライセンスを付与することになります。お客様は、営利目的および非営利目的を問わず、コンテンツの全体または一部を使用、ダウンロード、コピー、変更、または調整できる、取り消し不能で世界規模の、非独占的かつ無償のライセンスを付与するものとします。音声コンテンツの場合、このライセンスには、音声を公開の場で再生する権利、および音声と動画コンテンツを同期させる権利が含まれますが、これらに限定されません。なおこのライセンスには、手話データ収集ツール ライセンス、手話データ収集ツール音声ライセンス、または手話データ収集ツールが手話データ収集ツール API などを通じて随時提供するその他のライセンスの下で、コンテンツを配信する権利が含まれます。お客様は、本利用規約の下で、お客様に対してアトリビューションや補償が提供されることなく、本サービスを通じてお客様のコンテンツが第三者による個人用途および営利目的のために公開されることを承諾および確認したものとみなされます。

            お客様がアップロードしたコンテンツについては、お客様が単独で責任を負うものとします。お客様は次の事項を保証するものとします。
            <ul>
              <li>お客様が本ウェブサイトにアップロードしたコンテンツのすべての所有権を保有し、そのコンテンツが第三者の著作権、所有権、商標、またはその他該当する権利を侵害しないこと。</li>
              <li>音声コンテンツの場合、本利用規約で意図されている音声使用によって、音声を構成する音楽作品または著作物に存在する権利が侵害されないこと。</li>
              <li>非独占的で無期限、かつ取り消し不能で世界規模の無償で使用できるモデルおよび / またはプロパティ リリース、もしくは目的を問わない無条件のコンテンツ利用に必要なその他の許可を取得していること。ただし法律によって条件が定められている場合を除きます。</li>
            </ul>
            当社は最善の注意を払いますが、お客様は、お客様がアップロードしたコンテンツの誤用または不正使用を含むユーザーの行為または不作為について、手話データ収集ツールが責任を負わないことを承諾するものとします。

            また当社は、コンテンツに欠陥が認められる、低品質である、本利用規約に違反しているなど、理由のいかんを問わず、いつでもコンテンツを削除する権利を留保します。手話データ収集ツールでは、不適切な状況での使用があった場合や、ユーザーが常習的侵害者とみなされる場合に、サービスを停止するポリシーを採用しています。
            `,
          },
          "use-of-the-service": {
            title: "● サービスの利用",
            text: `お客様は本サービスの利用に関連して、データ マイニング、ロボット、スクレイピング、またはこれに類似するデータ収集 / 抽出手法を使用してはなりません。本サービスの基盤にある技術およびソフトウェア、または本サービスに伴って配信される技術およびソフトウェアは、手話データ収集ツールおよび当社のライセンサー、関係会社、パートナーの所有物であり、そのソフトウェアについてお客様にライセンスが付与されることはありません。お客様は、コピー、変更、二次的著作物の作成、リバース エンジニアリング、逆アセンブルまたはその他のソースコード解読の試み、かかる技術またはソフトウェアの権利の販売、譲渡、サブラインセンス供与、またはその他の方法による権利の譲渡を行わないことに同意するものとします。本利用規約で明示的に付与されていない権利は、手話データ収集ツールによって留保されています。上記のいずれかの方法を利用した場合を含め、コンテンツを大規模かつ系統的にコピーすることは、手話データ収集ツールによって明示的に許可されている場合を除き、禁止されています。

            これは、パブリック ドメインの一部として提供されているコンテンツを含むすべてのコンテンツに適用されます。本サービスは、著作権法、国際協定、およびその他の知的財産法に従い、集合著作物および / または編集物を対象とする著作権によって保護されています。
            `,
          },
          "notice-and-takedown-policy": {
            title: "● 通知および削除に関するポリシー",
            text: `手話データ収集ツールはクリエイティブの権利を尊重しています。そのため当社は、権利侵害の通知に速やかに対応することをポリシーとしています。本サービスを通じてアクセス可能なコンテンツによって、お客様の著作権、商標、またはその他の権利が侵害されたとお考えの場合は、当社まで以下の情報を書面でお知らせください。
            <ul>
              <li>侵害されていると考えられる著作物、商標、またはその他の権利を識別できる情報</li>
              <li>本サービス上で表示されている URL リンクなど、侵害されていると考えられる素材を識別できる情報</li>
              <li>お客様の連絡先情報（メールアドレスなど）</li>
              <li>申し立ての対象となる素材の使用が、著作権 / 商標 / その他の権利の所有者、代理人、または法律によって許可されていないことを確信している旨の表明。および上記の情報が正確で、自身が権利の所有者であるか、所有者の代理人として認められていることの宣言。</li>
            </ul>この情報は、contact@bit.studio までメールでお送りください。

            上記の内容は、法的な助言を構成するものではありません。適用法におけるお客様の権利と義務については、必要に応じて弁護士にお問い合わせください。`,
          },
          termination: {
            title: "● アカウントの停止",
            text: "当社は、事前の通知または法的責任の有無、ならびに理由のいかんを問わず、お客様のアカウントを直ちに終了または停止する場合があります。これにはお客様が本利用規約に違反している場合が含まれますがそれに限定されません。アカウントが停止されると、本ウェブサイトを利用するお客様の権利が直ちに停止します。",
          },
          "indemnification-for-breach-of-terms": {
            title: "● 利用規約違反に対する補償",
            text: "お客様は、手話データ収集ツールによって発生したすべての損失、支出、損害、費用を補償し免責することに同意するものとします。これには、お客様による本利用規約に対する違反によって直接的または間接的に生じた妥当な弁護士費用が含まれますが、それに限定されません。またお客様は、お客様によるウェブサイトの使用に起因する第三者によるあらゆる申し立てについて、手話データ収集ツールを補償し免責することにも同意するものとします。",
          },
          "warranty-and-liability": {
            title: "● 保証および責任",
            text: "本ウェブサイトおよびそのコンテンツは「現状有姿」で提供されるものです。当社は、コンテンツ、本ウェブサイト、情報の正確性、または本契約に基づく権利またはライセンスに関して、明示的であるか暗黙的であるかを問わず保証を提供することはありません。これには、商品性や用途に対する適合性に対する暗黙的な保証が含まれますが、これに限定されません。手話データ収集ツールは、本ウェブサイトまたはそのコンテンツがお客様の要件に適合すること、またはその使用が中断されずエラーが発生しないことを表明あるいは保証するものではありません。手話データ収集ツールは、お客様による本ウェブサイトまたはそのコンテンツの使用に起因する一般的損害、懲罰的損害、特別損害、間接的損害、結果的損害、偶発的損害、利益の逸失、もしくはその他の損害、費用、損失について、お客様またはその他の個人あるいは事業体に対して責任を負わないものとします。",
          },
        },
      },
    },
    chooseSession: {
      title: "一つ選んでください。",
      signsText: "単語",
      minsText: "分",
    },
    "sign-list": {
      title: "見本の手話と同じように表現してください。",
      changeButtonText: "別の単語にする",
      backButtonText: "戻る",
      startButtonText: "スタート",
    },
    record: {
      pleaseSignOnlyOnce: "※１回のみ表現してください（繰り返しなし）",
      pleaseCopyTheSampleVideo: "※見本と同じように表現してください",
      tooCloseText: "もっと離れて",
      tooFarText: "もっと近づいて",
      perfectText: "OK",
      readyText: "準備はいい？",
      uploadingText: "データ送信中",
      quitButtonText: "やめる",
      popup: {
        title: "もう一度見本を見ますか？",
        text: "これまでのデータは無事送信されています。",
        quitButtonText: "やめる",
        resumeButtonText: "再開する",
      },
      finish: {
        finishText: "完了！🎉",
        label: "単語",
        chooseOtherSignButtonText: "最初へ戻る",
        chooseOtherSessionButtonText: "メニューへ戻る",
      },
    },
  },
};

const japaneseConsent = {
  termsAndConditionJapaneseMapping: (url: string) => {
    return `「手話データ収集ツールへようこそ！

    本利用規約（以下「利用規約」、「契約」）は、Bit Studio のウェブサイト(<a href={${url}}>${url}</a>)の使用に関する規則や規制をまとめたものです。

    お客様は本ウェブサイトにアクセスすることで、本利用規約を承諾したとみなされます。このページに記載されている本利用規約の全体に同意しない限り、手話データ収集ツールの使用を続行しないでください。    

    以下に示す用語は、本利用規約、プライバシーに関する声明、免責条項、およびすべての契約で使用されます。「ユーザー」および「お客様」は、本文書の対象となるお客様を指します。「会社」、「ウェブサイト運営者」、「当社」は当社を指します。本契約は、本ウェブサイト <a href={${url}}>${url}</a> およびそのプロダクトまたはサービス（総称して「ウェブサイト」または「サービス」）の使用に関する一般的な利用規約を規定するものです。
    `;
  },
  rightsToPublicDomainJapaneseMapping: (url: string) => {
    return `以下に示すのは、お客様（以下「お客様」または「ユーザー」）と、<a href={${url}}>${url}</a> のサイト（以下「本ウェブサイト」）および当社が提供するすべての関連ウェブサイト、ソフトウェア、モバイルアプリ、プラグイン、その他のサービス（総称して「本サービス」）の所有者および運営者（以下「当社」または「手話データ収集ツール」）との間で締結された法的契約です。お客様による本サービスの使用と、当社によるサービスの提供には、お客様と手話データ収集ツールとの契約に基づき、本利用規約の条項（以下「利用規約」）が適用されます。

    「コンテンツ」とは、手話データ収集ツールのライセンスおよび手話データ収集ツールの音声ライセンスの下で利用できる、手話データ収集ツールにアップロードされたすべての画像、動画、音声を総称するものとします。「画像」には、写真、ベクター、描画、イラストが含まれます。「動画」は、あらゆる動画、アニメーション、映画、またはその他の音声 / 視覚表示を指します。「音声」は、音楽またはその他の音声ファイルを指します。

    当社は独自の裁量により、本利用規約の一部をいつでも変更または修正する権利を留保します。変更があった場合、当社は変更内容を本ページに掲載し、ページの最上部に本利用規約の最終改訂日を示します。そのような変更は直ちに効力を生じるものとします。変更の発効日以後もお客様が本サービスの利用を続行した場合、お客様は変更後の利用規約に同意したものとみなされます。
    `;
  },
};

export default japaneseMapping;
