import { FunctionalComponent, h } from "preact";
import { route, Route, Router } from "preact-router";

import Home from "../routes/home";
import Profile from "../routes/profile";
import NotFoundPage from "../routes/notfound";
import ChooseSignLanguage from "../routes/collect/chooseSignLanguage";
import Consent from "../routes/collect/consent";
import ChooseSession from "../routes/collect/chooseSession";
import SignList from "../routes/collect/signList";
import Record from "../routes/collect/record";
import Finish from "../routes/collect/finish";
import Review from "../routes/review";
import Div100vh from "react-div-100vh";
import { RecoilRoot, useRecoilValue } from "recoil";
import styled from "styled-components";
import { useEffect } from "preact/hooks";
import { termsAndConditionsAtom } from "../recoil/global";
import DropDownLanguages from "./languages";

const Layout = styled.div`
  width: 100%;
  height: 100%;
  max-width: 640px;
  margin: auto;

  background-color: #f9f9f9;
`;

const Version = styled.p`
  position: absolute;
  top: 1%;
  right: 5%;
  font-size: 0.8rem;
`;

const version: any = process.env.PREACT_APP_VER
  ? process.env.PREACT_APP_VER
  : "unknown";

const App: FunctionalComponent = () => {
  return (
    <RecoilRoot>
      <Div100vh id="preact_root">
        <LayoutEl />
      </Div100vh>
    </RecoilRoot>
  );
};

const LayoutEl: FunctionalComponent = () => {
  const termsAndConditions = useRecoilValue(termsAndConditionsAtom);

  useEffect(() => {
    console.log({ termsAndConditions });
    if (!termsAndConditions && window.location.pathname !== "/review") {
      route("/collect/choose-sign-language");
    }
  }, [termsAndConditions]);

  return (
    <Layout>
      <Router>
        <Route path="/review" component={() => <></>} />
        <Route default component={DropDownLanguages} />
      </Router>
      <Version>{version}</Version>
      <Router>
        <Home path="/" />
        {/* collect */}
        <ChooseSignLanguage path="/collect/choose-sign-language" />
        <Consent path="/collect/consent" />
        <ChooseSession path="/collect/choose-session" />
        <SignList path="/collect/sign-list" />
        <Record path="/collect/record" />
        <Finish path="/collect/finish" />

        {/* review */}
        <Review path="/review" />

        <Profile path="/profile/" user="me" />
        <Route path="/profile/:user" component={Profile} />
        <NotFoundPage default />
      </Router>
    </Layout>
  );
};

export default App;
