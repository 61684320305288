import * as tf from "@tensorflow/tfjs";
import mpFaceDetection from "@mediapipe/face_detection";

export class FaceDectionMediapipe {
  async loadModel() {
    return new Promise((resolve, reject) => {
      this.faceDetection = new mpFaceDetection.FaceDetection({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${file}`;
        },
      });
      const faceDetectionOptions = {
        selfieMode: true,
        model: "short",
        minDetectionConfidence: 0.5,
      };

      const canvas = document.createElement("canvas");
      const imageData = canvas.getContext("2d").createImageData(257, 257);

      this.faceDetection.setOptions(faceDetectionOptions);

      function firstInference() {
        console.log("finish first inference");
        resolve(true);
      }
      // console.log('start first inference');
      this.faceDetection.onResults(firstInference);
      this.faceDetection.send({ image: imageData });
      return true;
    });
  }

  async predictFace(imageData) {
    return new Promise(async (resolve, reject) => {
      try {
        function faceDetectionCallback(faceResults) {
          // console.log(faceResults.detections[0].landmarks);
          // console.log(faceResults.detections);
          if (faceResults) {
            // console.log(faceResults.detections.length);
            if (faceResults.detections.length === 1) {
              if (faceResults.detections[0]) {
                const diffEar =
                  (faceResults.detections[0].landmarks[5].x -
                    faceResults.detections[0].landmarks[4].x) *
                  1000;
                // console.log('diffEye: ', diffEye);
                resolve(Math.round(diffEar));
              }
            } else if (faceResults.detections.length < 1) {
              resolve("cannot find your face");
            } else {
              resolve("please let only one person in the frame");
            }
          }
        }
        // const videoEl = document.getElementById('videoEl');
        // console.log('check videoElement: ', videoEl);
        // if (videoEl) {
        //   this.faceDetection.onResults(faceDetectionCallback);
        //   await this.faceDetection.send({ image: videoEl });
        // } else {
        //   resolve('creating videoEl');
        // }
        if (imageData) {
          this.faceDetection.onResults(faceDetectionCallback);
          await this.faceDetection.send({ image: imageData });
        } else {
          resolve("creating videoEl");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
}
