import { Fragment, FunctionalComponent, h } from "preact";
import {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
} from "preact/hooks";
import { getDataFromApi } from "../../../utils/apiUtils";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  signingSessionAtom,
  signLaguageSelectionAtom,
  signLanguageList,
  signListAtom,
  signSelectionListAtom,
  signSelectionListT,
  signSelectionObjectT,
  webLanguageSelector,
} from "../../../recoil/global";
import styled from "styled-components";
import {
  fluidFont,
  PrimaryButtonStyle,
  SecondaryButtonStyle,
  Title,
} from "../../../style/globalStyle";
import { ButtonWrapperRow, Content } from "../consent";
import { route } from "preact-router";
import Loader from "../../../components/loader";
import accessNestedObj from "../../../utils/object";
import { definitionToString } from "../../../utils/signHelper";

const DATA_COLLECTION_SERVICE_ENDPOINT =
  process.env.PREACT_APP_DATA_COLLECTION_SERVICE_ENDPOINT;
const SIGN_DICTIONARY_SERVICE_ENDPOINT =
  process.env.PREACT_APP_SIGN_DICTIONARY_SERVICE_ENDPOINT;

const PAGE_PATH: string = "collect.sign-list";

const SignCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 40%; */
  width: 100%;

  margin: 1rem 0;
  padding: 0 1rem;

  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;

    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;

    ${fluidFont(16, 18)}

    p {
      margin: 0;
      text-transform: capitalize;
    }

    button {
      border: none;
      border-radius: 20px;
      padding: 0.5rem;

      font-family: "Google Sans";
      font-style: normal;
      font-weight: 500;
      ${fluidFont(13, 16)}

      color: #68aee8;
      background-color: transparent;
      transition: all 0.3s;

      cursor: pointer;
      :hover {
        color: white;
        background-color: #68aee8;
      }
    }
  }
`;

const SubTitle = styled(Title)`
  padding-top: 0;
  padding-bottom: 2%;
  color: #828282;
`;

const ContentSignList = styled(Content)`
  padding-bottom: 20vh;
`;
const ContentError = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  button {
    width: 20%;
  }
`;
const getNextSign = (arr: string[], index: number) => {
  let _nextSign = arr[index] !== undefined ? arr[index] : arr[arr.length - 1];
  return _nextSign;
};

const SignVideoAutoLoadStyle = styled.div`
  height: 40vh;
  video {
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
    margin: auto;
  }
`;

type SignVideoAutoLoadPropsT = {
  src?: string;
  scrollRef: HTMLDivElement;
};

const SignVideoAutoLoad: FunctionalComponent<SignVideoAutoLoadPropsT> = (
  props: SignVideoAutoLoadPropsT
) => {
  const [videoVisible, setVideoVisible] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    props.scrollRef.addEventListener("scroll", scrollHandler);
    return () => props.scrollRef.removeEventListener("scroll", scrollHandler);
  }, []);

  useLayoutEffect(() => {
    scrollHandler();
  });

  const scrollHandler = () => {
    // console.log(videoRef.current.offsetTop,videoVisible)
    if (videoRef && videoRef.current && props.scrollRef) {
      const topPos = props.scrollRef.scrollTop;
      const bottomPos = props.scrollRef.scrollTop + window.innerHeight;

      // console.log(videoRef.current.offsetTop,topPos, bottomPos,videoRef.current.clientHeight );
      if (
        bottomPos > videoRef.current.offsetTop &&
        topPos < videoRef.current.offsetTop + videoRef.current.clientHeight
      ) {
        if (videoRef.current.src !== props.src) {
          // console.log(videoRef.current.offsetTop, true);
          setVideoVisible(true);
        }
      } else {
        if (videoRef.current.src === props.src) {
          // console.log(videoRef.current.offsetTop, false);
          setVideoVisible(false);
        }
      }
    }
  };
  return (
    <SignVideoAutoLoadStyle>
      <video
        ref={videoRef}
        poster="/assets/images/user.svg"
        src={videoVisible ? props.src : ""}
        autoPlay
        loop
        muted
        playsInline
      />
    </SignVideoAutoLoadStyle>
  );
};

const SignList: FunctionalComponent = () => {
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);

  const [signList, updateSignList] = useState([] as string[]);
  const [signSelectionList, updateSignSelectionList] = useRecoilState(
    signSelectionListAtom
  );
  const [isFetching, updateIsFetching] = useState(true);
  const [isError, updateIsError] = useState(false);
  const signingSession = useRecoilValue(signingSessionAtom);
  const [nextSign, updateNextSign] = useState<number>(
    signingSession.sessionSelect
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  const signLanguageSelection = useRecoilValue(signLaguageSelectionAtom);

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );

  useEffect(() => {
    if (signList.length === 0) {
      getDataFromApi(
        `${DATA_COLLECTION_SERVICE_ENDPOINT}/signLanguages/${signLanguageList[signLanguageSelection].key}/signs?collectingStatus=true`
      )
        .then((res: any) => {
          console.log("got signlist");

          let signingSelection: signSelectionListT = {};
          for (let i = 0; i < signingSession.sessionSelect; i++) {
            signingSelection[i] = {
              signId: res[i].id,
              signLanguageId: signLanguageList[signLanguageSelection].key,
              text: res[i].signDictionaryData.signDefinitions,
              videoUrl: res[i]?.signDictionaryData?.defaultVideoUrl as string,
            };
          }
          finishTextVideoBatchRequest(signingSelection);
          updateSignList((res as any[]).map((e) => e.id));
        })
        .catch((err) => {
          console.log(err);
          updateIsError(true);
        });
    }
    console.log(signingSession);
    console.log(signLanguageSelection);
    console.log({ signingSession, signLanguageSelection, signSelectionList });
  }, []);

  const finishTextVideoBatchRequest = (List: signSelectionListT) => {
    console.log("finish!", List);
    updateSignSelectionList(List);
    updateIsFetching(false);
  };

  // const getFirstBatchSignList = () => {
  //   console.log({ signList });
  //   // TODO: get video from signList, lazy render video only on frame
  //   /**
  //    * sign selection list
  //    * title: string,
  //    * videoUrl: string
  //    */

  //   let signingSelection: signSelectionListT = {};
  //   let finishedCounter = 0;

  //   for (let i = 0; i < signingSession.sessionSelect; i++) {
  //     signingSelection[i] = {
  //       signId: signList[i],
  //       signLanguageId: signLanguageList[signLanguageSelection].key,
  //     };
  //     getDataFromApi(
  //       `${DATA_COLLECTION_SERVICE_ENDPOINT}/signLanguages/${signLanguageList[signLanguageSelection].key}/signs/${signList[i]}`
  //     )
  //       .then((res: any) => {
  //         if (res) {
  //           signingSelection[i] = {
  //             ...signingSelection[i],
  //             text: res.signDictionaryData.signDefinitions,
  //             videoUrl: res.sampleUrl as string,
  //           };
  //         }
  //         finishedCounter += 1;
  //         if (finishedCounter === signingSession.sessionSelect )
  //           finishTextVideoBatchRequest(signingSelection);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         updateIsError(true);
  //       });
  //   }
  // };

  // useEffect(() => {
  //   if (signList.length > 0) {
  //     getFirstBatchSignList();
  //   }
  // }, [signList]);

  const handleGetSignSelectionObj = (key: number, signId: string) => {
    let signSelectionObject: signSelectionObjectT = { signId: signId };
    getDataFromApi(
      `${DATA_COLLECTION_SERVICE_ENDPOINT}/signLanguages/${signLanguageList[signLanguageSelection].key}/signs/${signId}`
    )
      .then((res: any) => {
        if (res) {
          signSelectionObject = {
            ...signSelectionObject,
            signLanguageId: signLanguageList[signLanguageSelection].key,
            text: res.signDictionaryData.signDefinitions,
            videoUrl: res?.signDictionaryData?.defaultVideoUrl as string,
          };
        }
        finishTextVideoRequest(signSelectionObject, key);
      })
      .catch((err) => {
        console.log(err);
        updateIsError(true);
      });
  };

  const finishTextVideoRequest = (obj: signSelectionObjectT, key: number) => {
    console.log("finish!", obj);
    // do swap obj
    let signSelectionBuffer = { ...signSelectionList };
    signSelectionBuffer[key] = obj;
    console.log({ signSelectionBuffer, signSelectionList });
    updateSignSelectionList(signSelectionBuffer);
  };

  const handleChangeSign = (input: number) => {
    let nextSignId = signList[nextSign];
    if (nextSignId) {
      handleGetSignSelectionObj(input, nextSignId);
      updateNextSign(nextSign + 1);
    } else {
      alert("no more signs to change");
    }
  };

  const handleClickButton = (input: number) => {
    switch (input) {
      case 0:
        route("/collect/choose-session");
        break;
      case 1:
        route("/collect/record");
        break;
    }
  };

  const handleRetry = () => {
    if (typeof window !== "undefined") {
      window.location.reload();
    }
  };
  return (
    <Fragment>
      {isError && (
        <Fragment>
          <ContentError>
            Something went wrong, <br /> please try again
            <PrimaryButtonStyle onClick={handleRetry}>Retry</PrimaryButtonStyle>
          </ContentError>
        </Fragment>
      )}
      {!isError && (
        <Fragment>
          <ContentSignList ref={scrollRef}>
            <Title>{language.title}</Title>
            <SubTitle>{signLanguageList[signLanguageSelection].key}</SubTitle>
            {isFetching && <Loader />}

            {signSelectionList &&
              !isFetching &&
              Object.keys(signSelectionList).map((key, idx) => {
                if (idx >= signingSession.sessionSelect) return;
                let textDisplay = () => {
                  let textDis = definitionToString(
                    signSelectionList[key].text.en
                  );
                  let translationTextDis = definitionToString(
                    signSelectionList[key].text[
                      signLanguageList[signLanguageSelection].translation
                    ]
                  );
                  if (translationTextDis && translationTextDis !== textDis) {
                    textDis = `${textDis} (${translationTextDis})`;
                  }
                  return textDis;
                };
                return (
                  <SignCardStyle key={idx}>
                    <div>
                      <p>
                        {idx + 1}. {textDisplay()}
                      </p>
                      <button key={idx} onClick={() => handleChangeSign(idx)}>
                        {language.changeButtonText}
                      </button>
                    </div>
                    {scrollRef && scrollRef.current && (
                      <SignVideoAutoLoad
                        src={signSelectionList[key].videoUrl}
                        scrollRef={scrollRef.current}
                      />
                    )}
                  </SignCardStyle>
                );
              })}
          </ContentSignList>

          <ButtonWrapperRow>
            <SecondaryButtonStyle onClick={() => handleClickButton(0)}>
              {language.backButtonText}
            </SecondaryButtonStyle>
            <PrimaryButtonStyle
              onClick={() => handleClickButton(1)}
              disabled={isFetching}
            >
              {language.startButtonText}
            </PrimaryButtonStyle>
          </ButtonWrapperRow>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SignList;
