import { Fragment, FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { useMemo, useState, useEffect } from "preact/hooks";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { getDataFromApi } from "../../../utils/apiUtils";
import Loader from "../../../components/loader";
import {
  // sessionSelectListSelector,
  signLanguageList,
  signingSessionAtom,
  signSelectionListAtom,
  signLaguageSelectionAtom,
  webLanguageSelector,
  sessionSelectList,
} from "../../../recoil/global";
import {
  SelectionButton,
  SelectionTitle,
  SelectionWrapper,
} from "../../../style/globalStyle";
import accessNestedObj from "../../../utils/object";

const PAGE_PATH: string = "collect.chooseSession";
const DATA_COLLECTION_SERVICE_ENDPOINT =
  process.env.PREACT_APP_DATA_COLLECTION_SERVICE_ENDPOINT;

const ChooseSession: FunctionalComponent = () => {
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);
  // const sessionSelectList = useRecoilValue(sessionSelectListSelector);

  const [, updateSigningSession] = useRecoilState(signingSessionAtom);
  const [, updateSignSelectionList] = useRecoilState(signSelectionListAtom);
  const [singLanguageIndex] = useRecoilState(signLaguageSelectionAtom);

  const [signCount, setSignCount] = useState<number | null>(null);
  const [currentSessionSelectList, setCurrentSessionSelectList] =
    useState(sessionSelectList);

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );
  /**
   * TODO: after click
   * - send signs to api endpoints
   */

  useEffect(() => {
    if (signCount === null) {
      getDataFromApi(
        `${DATA_COLLECTION_SERVICE_ENDPOINT}/signLanguages/${signLanguageList[singLanguageIndex].key}/signs?collectingStatus=true`
      )
        .then((res: any) => {
          const count = res.length;
          var filteredSessionSelectList = sessionSelectList.filter(
            (item) => item.value <= count
          );
          if (filteredSessionSelectList.length === 0 && count > 0) {
            filteredSessionSelectList = [
              {
                title: `${count} Signs`,
                duration: Math.ceil((3 / 10) * count),
                value: count,
              },
            ];
          }

          const signs: string = language.signsText;
          const mins: string = language.minsText;
          const newSessionSelectList = filteredSessionSelectList.map((x) => ({
            ...x,
            title: `${x.value} ${signs} (${x.duration} ${mins})`,
          }));

          setCurrentSessionSelectList(newSessionSelectList);
          setSignCount(count);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setCurrentSessionSelectList([]);
      setSignCount(0);
    }
  }, []);

  const handleSelectSession = (input: number) => {
    updateSigningSession({
      sessionSelect: input,
      sessionProgress: 0,
    });
    updateSignSelectionList({});
    route("/collect/sign-list");
  };
  return (
    <Fragment>
      <SelectionTitle>
        {language.title}
        {` (${signLanguageList[singLanguageIndex].key})`}
      </SelectionTitle>
      {signCount === null && <Loader />}
      {signCount === 0 && (
        <SelectionTitle>There is no collecting sign!</SelectionTitle>
      )}
      {signCount !== null && signCount > 0 && (
        <SelectionWrapper>
          {currentSessionSelectList
            .filter((item) => item.value <= signCount)
            .map((item, idx) => {
              return (
                <SelectionButton
                  key={idx}
                  onClick={() => handleSelectSession(item.value)}
                >
                  {item.title}
                </SelectionButton>
              );
            })}
        </SelectionWrapper>
      )}
    </Fragment>
  );
};

export default ChooseSession;
