import { atom, selector } from "recoil";
import { localStorageEffect } from "./localStorageEffect";
import language from "../assets/language";

// export const signLanguageList = [
//   {
//     title: "Japanese Sign Language (JSL)",
//     key: "JSL",
//     translation: "jp",
//   },
//   {
//     title: "Hong Kong Sign Language (HKSL)",
//     key: "HKSL",
//     translation: "zh-hk",
//   },
//   {
//     title: "Indian Sign Language (ISL)",
//     key: "ISL",
//     translation: "",
//   },
//   {
//     title: "American Sign Language (ASL)",
//     key: "ASL",
//     translation: "",
//   },
//   {
//     title: "Indonesian Sign Language (BISINDO)",
//     key: "BISINDO",
//     translation: "",
//   },
// ];

export const signLanguageList = [
  {
    title: "Hong Kong Sign Language (HKSL)",
    key: "HKSL",
    translation: "zh-hk",
    maxSignCountInSession: 200,
  },
  {
    title: "Japanese Sign Language (JSL)",
    key: "JSL",
    translation: "ja",
    maxSignCountInSession: 200,
  },
  {
    title: "Yangon Sign Language (YSL)",
    key: "YSL",
    translation: "en",
    maxSignCountInSession: 10,
  },
];

export const signLaguageSelectionAtom = atom<number>({
  key: "signLanguage",
  default: 0,
  effects_UNSTABLE: [localStorageEffect(`signLanguage`)],
});
export const signLanguageSelector = selector({
  key: "signLanguageSelector",
  get: ({ get }) => {
    const lang: webLanguage = get(webLanguageAtom);
    const languageDict: any = language[lang];
    const newSignLanguageList = signLanguageList.map((x) => ({
      ...x,
      title: languageDict.collect.chooseSignLanguage.signLanguage[x.key],
    }));

    return newSignLanguageList;
  },
});

export const termsAndConditionsAtom = atom({
  key: "termsAndConditions",
  default: false,
  effects_UNSTABLE: [localStorageEffect(`termsAndConditions`)],
});

export enum rightToPublicDomainList {
  idel,
  no,
  yes,
}
export const rightsToPublicDomainAtom = atom<rightToPublicDomainList>({
  key: "rightsToPublicDomain",
  default: 0,
  effects_UNSTABLE: [localStorageEffect(`rightsToPublicDomain`)],
});

export const sessionSelectList = [
  {
    title: "10 Signs (3 mins)",
    duration: 3,
    value: 10,
  },
  {
    title: "20 Signs (6 mins)",
    duration: 6,
    value: 20,
  },
  {
    title: "50 Signs (15 mins)",
    duration: 15,
    value: 50,
  },
  {
    title: "100 Signs (30 mins)",
    duration: 30,
    value: 100,
  },
  {
    title: "200 Signs (60 mins)",
    duration: 60,
    value: 200,
  },
];
// export const sessionSelectListSelector = selector({
//   key: "sessionSelectListSelector",
//   get: ({ get }) => {
//     const lang: webLanguage = get(webLanguageAtom);
//     const languageDict: any = language[lang];
//     const signs: string = languageDict.collect.chooseSession.signsText;
//     const mins: string = languageDict.collect.chooseSession.minsText;
//     const newSessionSelectList = sessionSelectList.map((x) => ({
//       ...x,
//       title: `${x.value} ${signs} (${x.duration} ${mins})`,
//     }));

//     return newSessionSelectList;
//   },
// });

export type signingSessionT = {
  sessionSelect: number;
  sessionProgress: number;
};
export const signingSessionAtom = atom<signingSessionT>({
  key: "session",
  default: {
    sessionSelect: 0,
    sessionProgress: 0,
  },
  effects_UNSTABLE: [localStorageEffect(`session`)],
});

export enum errorE {
  IDLE,
  CAMERA_PERMISSION,
  CAMERA_NOT_DETECT,
  API_ERROR,
  INCORRECT_BROWSER,
}
export const errorAtom = atom<errorE>({
  key: "error",
  default: errorE.IDLE,
});

export type signListT = string[];
export const signListAtom = atom<signListT>({
  key: "signList",
  default: [],
  // effects_UNSTABLE: [localStorageEffect(`signList`)],
});

export type signSelectionObjectT = {
  signId?: string;
  text?: any;
  videoUrl?: string;
  signLanguageId?: string;
};
export type signSelectionListT = {
  [key: string]: signSelectionObjectT;
};
export const signSelectionListAtom = atom<signSelectionListT>({
  key: "signSelectionList",
  default: {},
  effects_UNSTABLE: [localStorageEffect(`signSelectionList`)],
});

export enum webLanguage {
  EN = "en",
  JP = "jp",
}
export const webLanguageAtom = atom<webLanguage>({
  key: "webLanguage",
  default: webLanguage.EN,
});
export const webLanguageSelector = selector({
  key: "webLanguageSelector",
  get: ({ get }) => {
    const lang: webLanguage = get(webLanguageAtom);
    return language[lang];
  },
});
