import { Fragment, h } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import { ForwardFn, forwardRef } from "preact/compat";
import { useRef, useImperativeHandle } from "preact/hooks";
import styled from "styled-components";
import { FaceDectionMediapipe } from "../utils/faceDetection";
import JSZip from "../utils/jszip.min.js";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  errorAtom,
  errorE,
  rightsToPublicDomainAtom,
  signLaguageSelectionAtom,
  webLanguageSelector,
} from "../recoil/global";
import axios from "axios";
import { record } from "../routes/collect/record";
import { isMobile } from "react-device-detect";
import accessNestedObj from "../utils/object";

const PAGE_PATH: string = "collect.record";

/**
 * TODO: handle clear useEffect hook
 * connect send 16 frame to cloud
 */
const VideoStyle = styled.video`
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  object-fit: cover;
  width: 100%;
  transform: translate(-50%, 0) rotateY(180deg);
  border-radius: 6px;
`;
const CanvasCaptureStyle = styled.canvas`
  position: absolute;
  width: 100%;
  opacity: 0;
  object-fit: contain;
  transform: rotateY(180deg);
  z-index: -1;
`;
const VideoWrapper = styled.div`
  // position: absolute;
  width: 90vw;
  height: 90vw;
  max-width: min(640px, 60vh);
  max-height: min(640px, 60vh);
  overflow: hidden;
`;
const HumanGuide = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  object-fit: cover;
  z-index: 10;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;
const AlearText = styled.p`
  font-size: calc(24px * var(--scale, 1));
  font-weight: 400;
  width: max-content;
  color: #fff;
  position: absolute;
  top: 44%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  font-family: "Google Sans", Quicksand, source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
`;
const VideoBorder = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  border: 0.3rem solid black;
  border-radius: 6px;
  box-sizing: border-box;
  z-index: 11;
`;

type CameraComponentsProps = {
  action?: () => void;
  signingState: string;
  handleStart?: () => void;
  handleSetupCamera: () => void;
  pageState: number;
  faceModel: FaceDectionMediapipe;
  signId?: string;
  signLanguageId?: string;
  isQuitPopup: boolean;
  handeUpdatePageState: (state: number) => void;
};

export type CameraComponentsHandle = {
  captureImage: () =>
    | false
    | {
        imageData: ImageData | undefined;
        imageUrl: string;
      };
  start: () => void;
  startRecording: () => Promise<any[]> | undefined;
  cleanupBuffer: () => void;
};

const farBuffer_ = 180;
const closeBuffer_ = 220;

const DATA_COLLECTION_SERVICE_ENDPOINT =
  process.env.PREACT_APP_DATA_COLLECTION_SERVICE_ENDPOINT;

let farBuffer = farBuffer_;
let closeBuffer = closeBuffer_;

export type frameBufferType = {
  data: Blob;
  delay: number;
  timestamp: number;
  index: number;
};
let frameBuffer: frameBufferType[] = [];
let __requestAnimationFrame: number;
const Camera: ForwardFn<any, CameraComponentsHandle> = (
  props: CameraComponentsProps,
  forwardedRef
) => {
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);

  const videoRef = useRef<any>(null);
  const canvasCaptureRef = useRef<HTMLCanvasElement>(null);

  const [isVideoPlayed, updateIsVideoPlayed] = useState<boolean>(false);
  const [isCameraSetup, updateIsCameraSetup] = useState<boolean>(false);

  const [startPoseCheck, updateStartPoseCheck] = useState(true);
  const [isClassify, updateIsClassify] = useState(false);

  const [playerPositionState, updatePlayerPositionState] = useState("");
  // const [cameraPermissionState, updateCameraPermissionState] = useState<string>(
  //   'idle',
  // );
  const [, updateCameraError] = useState("");
  const [isMobileCheck] = useState<boolean>(isMobile);
  const rightsToPublicDomain = useRecoilValue(rightsToPublicDomainAtom);
  const [, updateError] = useRecoilState(errorAtom);

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );

  const setupCamera = async () => {
    const constraints = {
      audio: false,
      video: {
        facingMode: "user", // 'user' or 'environment'
      },
    };
    if (videoRef.current !== null) {
      console.log("setup camera");
      const mediaStream = await navigator.mediaDevices
        .getUserMedia(constraints)
        .catch((err) => {
          console.log(err.name);
          updateIsCameraSetup(false);
          if (props.action) props.action();
          if (err.name === "NotAllowedError") {
            updateCameraError("NotAllowedError");
            updateError(errorE.CAMERA_PERMISSION);
            return;
          } else {
            updateCameraError("NotDetect");
            updateError(errorE.CAMERA_NOT_DETECT);
            return;
          }
        });
      if (mediaStream) {
        videoRef.current.srcObject = mediaStream;
        videoRef.current.captureStream =
          videoRef.current.captureStream || videoRef.current.mozCaptureStream;

        console.log(`--- set up camera ---`);
        updateIsCameraSetup(true);
        props.handleSetupCamera();
        // updateCameraPermissionState('allow');
      }
    } else return;
  };

  const onLoadedVideo = () => {
    console.log("loadedVideoData");
    if (
      videoRef.current !== null &&
      canvasCaptureRef.current !== null &&
      !isVideoPlayed &&
      isCameraSetup
    ) {
      updateIsVideoPlayed(true);
      videoRef.current.play();
    }
  };

  function wait(delayInMS: number) {
    return new Promise((resolve) => setTimeout(resolve, delayInMS));
  }

  const startRecording = () => {
    const lengthInMS = 5000;
    if (videoRef.current !== null) {
      let recorder = new MediaRecorder(
        videoRef.current.captureStream() as MediaStream
      );
      let data: any[] = [];

      recorder.ondataavailable = (event) => data.push(event.data);
      recorder.start();

      let stopped = new Promise((resolve, reject) => {
        recorder.onstop = resolve;
        recorder.onerror = (event) => reject((event as any).name);
      });

      let recorded = wait(lengthInMS).then(
        () => recorder.state == "recording" && recorder.stop()
      );

      return Promise.all([stopped, recorded]).then(() => data);
    }
  };

  useEffect(() => {
    if (videoRef.current !== null) setupCamera();
  }, [videoRef]);

  useEffect(() => {
    if (canvasCaptureRef.current !== null) onLoadedVideo();
  }, [canvasCaptureRef]);

  const captureImage = () => {
    const canvasCtx = canvasCaptureRef.current?.getContext("2d");
    if (
      canvasCaptureRef.current?.width !== undefined &&
      canvasCaptureRef.current?.height !== undefined &&
      videoRef.current !== null
    ) {
      // console.log('capture Image !');
      canvasCtx?.clearRect(
        0,
        0,
        canvasCaptureRef.current?.width,
        canvasCaptureRef.current?.height
      );
      isMobileCheck
        ? canvasCtx?.drawImage(
            videoRef.current,
            0,
            (videoRef.current.videoHeight - videoRef.current.videoWidth) / 2,
            videoRef.current?.videoWidth,
            videoRef.current?.videoWidth,
            0,
            0,
            canvasCaptureRef.current?.width,
            canvasCaptureRef.current?.height
          )
        : canvasCtx?.drawImage(
            videoRef.current,
            (videoRef.current.videoWidth - videoRef.current.videoHeight) / 2,
            0,
            videoRef.current?.videoHeight,
            videoRef.current?.videoHeight,
            0,
            0,
            canvasCaptureRef.current?.width,
            canvasCaptureRef.current?.height
          );
      return {
        imageData: canvasCtx?.getImageData(
          0,
          0,
          canvasCaptureRef.current.width,
          canvasCaptureRef.current.height
        ),
        imageUrl: canvasCaptureRef.current?.toDataURL("image/png"),
      };
    } else return false;
  };

  const captureBlob = (delay: number, current: number, idx: number) => {
    const canvasCtx = canvasCaptureRef.current?.getContext("2d");
    if (
      canvasCaptureRef.current?.width !== undefined &&
      canvasCaptureRef.current?.height !== undefined &&
      videoRef.current !== null
    ) {
      // console.log('capture Image !');
      canvasCtx?.clearRect(
        0,
        0,
        canvasCaptureRef.current?.width,
        canvasCaptureRef.current?.height
      );
      isMobileCheck
        ? canvasCtx?.drawImage(
            videoRef.current,
            0,
            (videoRef.current.videoHeight - videoRef.current.videoWidth) / 2,
            videoRef.current?.videoWidth,
            videoRef.current?.videoWidth,
            0,
            0,
            canvasCaptureRef.current?.width,
            canvasCaptureRef.current?.height
          )
        : canvasCtx?.drawImage(
            videoRef.current,
            (videoRef.current.videoWidth - videoRef.current.videoHeight) / 2,
            0,
            videoRef.current?.videoHeight,
            videoRef.current?.videoHeight,
            0,
            0,
            canvasCaptureRef.current?.width,
            canvasCaptureRef.current?.height
          );
      return canvasCaptureRef.current.toBlob((blob) => {
        if (blob) {
          frameBuffer.push({
            data: blob,
            delay,
            timestamp: current,
            index: idx,
          });
        }
      }, "image/jpeg");
    } else return false;
  };

  useEffect(() => {
    if (
      isVideoPlayed &&
      isCameraSetup &&
      startPoseCheck &&
      !isClassify &&
      props.pageState === record.positioning &&
      canvasCaptureRef.current !== null
    ) {
      const poseCheck = async (image: {
        imageData: ImageData;
        imageURL: string;
      }) => {
        const diffEar = await props.faceModel.predictFace(image.imageData);
        let readyTimeout: NodeJS.Timeout | undefined;
        if (typeof diffEar === "number") {
          // console.log(diffEar,closeBuffer,farBuffer);
          if (diffEar > closeBuffer) {
            updatePlayerPositionState("close");
            if (readyTimeout) clearTimeout(readyTimeout);
            readyTimeout = undefined;
            closeBuffer = closeBuffer_;
          } else if (diffEar < farBuffer) {
            updatePlayerPositionState("far");
            if (readyTimeout) clearTimeout(readyTimeout);
            readyTimeout = undefined;
            farBuffer = farBuffer_;
          } else {
            updatePlayerPositionState("ok");
            // console.log(readyTimeout);
            if (!readyTimeout) {
              readyTimeout = setTimeout(() => {
                if (props.handleStart) props.handleStart();
                if (readyTimeout) clearTimeout(readyTimeout);
                readyTimeout = undefined;
              }, 2000);
            }
            if (farBuffer === farBuffer_ || closeBuffer === closeBuffer_) {
              closeBuffer = closeBuffer_ + 30;
              farBuffer = farBuffer_ - 30;
            }
          }

          // console.log(diffEar);
        } else {
          updatePlayerPositionState("");
          // console.log(diffEar);
        }
        setTimeout(() => {
          updateStartPoseCheck(true);
        }, 20);
      };

      const image = captureImage();
      if (image && image.imageData) {
        poseCheck({ imageData: image.imageData, imageURL: image.imageUrl });
      }
      updateStartPoseCheck(false);
    }
  }, [
    startPoseCheck,
    isVideoPlayed,
    isCameraSetup,
    props.pageState,
    canvasCaptureRef,
  ]);

  useImperativeHandle(forwardedRef, () => ({
    captureImage: captureImage,
    start: function start() {
      console.log("start");
    },
    startRecording: startRecording,
    cleanupBuffer: cleanupBuffer,
  }));

  const cleanupBuffer = () => {
    console.log("cleanup buffer");
    frameBuffer = [];
    frameBuffer.length = 0;
    updateFinishSnapInterval(false);
    cancelAnimationFrame(__requestAnimationFrame);
  };

  const [finishSnapInterval, updateFinishSnapInterval] = useState(false);

  useEffect(() => {
    console.log({ finishSnapInterval, isQuitPopup: props.isQuitPopup });
    if (finishSnapInterval && !props.isQuitPopup) {
      // convert to zip jpg
      console.log("convert: isQuitPopup", props.isQuitPopup);
      props.handeUpdatePageState(record.uploading);
      console.log("convert to zip");

      let zip = new JSZip();
      for (let i = 0; i < frameBuffer.length; i++) {
        const frame = frameBuffer[i];
        const filename = `${~~((frame.timestamp * 25) / 1000)}.jpg`;
        zip.file(
          filename,
          new File([frame.data], filename, { type: "image/jpeg" })
        );
      }

      const uploadFile = async () => {
        if (props.signId === undefined) return;

        const file = await zip.generateAsync({ type: "blob" });

        const consent = rightsToPublicDomain === 2 ? "public" : "private";
        // const signLanguageId = props.signId.split("_")[0].toUpperCase();
        const extension = "zip";
        const uploadUrlResponse = await fetch(
          `${DATA_COLLECTION_SERVICE_ENDPOINT}/signLanguages/${props.signLanguageId}/collectedSignVideos?signId=${props.signId}&consent=${consent}&ext=${extension}`,
          {
            method: "POST",
          }
        );
        const uploadUrlResult = await uploadUrlResponse.json();
        console.log(uploadUrlResult);

        const res = await fetch(uploadUrlResult.url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": "application/zip",
          },
        });

        console.log({ uploadStatus: res.status });

        console.log("saved to cloud storage");

        // cleanup
        zip = null;
        frameBuffer.length = 0;
        updateFinishSnapInterval(false);
        props.handeUpdatePageState(record.uploaded);
      };
      uploadFile();
    }
  }, [finishSnapInterval]);

  useEffect(() => {
    if (
      props.pageState === record.recording &&
      isVideoPlayed &&
      isCameraSetup
    ) {
      // startRecording()?.then((recordedChunks) => {
      //   let recordedBlob = new Blob(recordedChunks, { type: "video/webm" });
      //   console.log(URL.createObjectURL(recordedBlob));
      //   // let a = document.createElement("a");
      //   // a.href = URL.createObjectURL(recordedBlob);
      //   // a.download = "RecordedVideo.webm";
      //   // a.click();
      // });
      snapInterval(25, 5000).then(async () => {
        console.log("finish interval");
        updateFinishSnapInterval(true);
      });
    }
  }, [props.pageState, isVideoPlayed, isCameraSetup]);

  const snapInterval = (fps: number, duration: number) => {
    frameBuffer = [];
    frameBuffer.length = 0;
    // const canvas = document.createElement("canvas"),
    //   ctx = canvas.getContext("2d");
    // canvas.width = 300;
    // canvas.height = 300;

    return new Promise(async (resolve) => {
      const start = performance.now();
      let then = start;
      const FPS_INTERVAL = 1000 / fps;
      let elapsed = 0;
      let index = 0;
      console.log("start record interval");
      console.log({ start, then, FPS_INTERVAL });

      const recordInterval = () => {
        const now = performance.now();
        const current = now - start;
        elapsed = now - then;

        // console.log("snap interval: ", current);

        if (current < duration) {
          if (elapsed > FPS_INTERVAL) {
            // ctx?.clearRect(0, 0, 300, 300);
            // ctx?.drawImage(
            //   videoRef.current,
            //   (videoRef.current.videoWidth - videoRef.current.videoHeight) / 2,
            //   0,
            //   videoRef.current?.videoHeight,
            //   videoRef.current?.videoHeight,
            //   0,
            //   0,
            //   300,
            //   300
            // );
            const delay = elapsed;
            const idx = index++;
            then = now;

            captureBlob(delay, current, idx);

            // canvas.toBlob((blob) => {
            //   if (blob) {
            //     frameBuffer.push({
            //       data: blob,
            //       delay,
            //       timestamp: current,
            //       index: idx,
            //     });
            //   }
            // }, "image/jpeg");
          }

          __requestAnimationFrame = requestAnimationFrame(recordInterval);
        } else {
          resolve(true);
        }
      };
      recordInterval();
    });
  };

  return (
    <Fragment>
      <VideoWrapper>
        {props.pageState === 1 && (
          <>
            <HumanGuide src={"../../assets/record/guide_human.png"} />
            {playerPositionState !== "ok" ? (
              <AlearText>
                {playerPositionState === "close" && language.tooCloseText}
                {playerPositionState === "far" && language.tooFarText}
              </AlearText>
            ) : (
              <AlearText>{language.perfectText}</AlearText>
            )}
          </>
        )}
        {props.signingState !== "processing" &&
          props.signingState !== "senddata" && <VideoBorder />}
        <VideoStyle
          ref={videoRef}
          autoPlay={true}
          onLoadedData={onLoadedVideo}
          playsInline
          id="videoEl"
        />
      </VideoWrapper>
      <CanvasCaptureStyle
        id="canvasEl"
        ref={canvasCaptureRef}
        width={300}
        height={300}
      />
    </Fragment>
  );
};

export default forwardRef(Camera);
