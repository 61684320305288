import { Fragment, FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import { useRecoilState, useRecoilValue } from "recoil";
import styled, { keyframes } from "styled-components";
import Camera, { CameraComponentsHandle } from "../../../components/camera";
import Loader from "../../../components/loader";
import {
  errorAtom,
  errorE,
  signingSessionAtom,
  signLaguageSelectionAtom,
  signLanguageList,
  signListAtom,
  signSelectionListAtom,
  signSelectionObjectT,
  webLanguageSelector,
} from "../../../recoil/global";
import {
  fluidFont,
  PrimaryButtonStyle,
  SecondaryButtonStyle,
  Title as TitleBase,
} from "../../../style/globalStyle";
import { FaceDectionMediapipe } from "../../../utils/faceDetection";
import { ButtonWrapper, Content } from "../consent";
import Sample from "./Sample";
import accessNestedObj from "../../../utils/object";
import { definitionToString } from "../../../utils/signHelper";

const PAGE_PATH: string = "collect.record";

// record state
export enum record {
  sample,
  positioning,
  getready,
  recording,
  recorded,
  uploading,
  uploaded,
  finish,
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8%;
  padding-left: 5%;
  padding-right: 5%;
`;

const HeaderNote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Number = styled(TitleBase)`
  text-transform: capitalize;
  padding-top: 0%;
  margin-bottom: 0;
  font-weight: 500;
  color: #828282;
  ${fluidFont(18, 22)}
`;

const Title = styled(TitleBase)`
  text-transform: capitalize;
  padding-top: 0%;
  margin-bottom: 0;
  color: black;
  ${fluidFont(18, 22)};
`;

const TitleNote = styled(TitleBase)`
  padding-top: 0%;
  margin-bottom: 0;
  color: red;
  ${fluidFont(10, 14)};
`;

const CameraContainer = styled.section`
  width: 100%;
  height: 70%;
  margin-bottom: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CameraWrapper = styled.section`
  width: 90vw;
  height: 90vw;
  max-height: min(640px, 60vh);
  max-width: min(640px, 60vh);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Aligned = styled.div`
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.6;
  border-radius: 6px;
  box-sizing: border-box;

  position: absolute;
  overflow: hidden;
`;

const AlearText = styled.p`
  font-size: calc(24px * var(--scale, 1));
  font-weight: 400;
  width: max-content;
  color: #fff;
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  font-family: "Google Sans", Quicksand, source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
`;

const recordAnimation = keyframes`
  0% {width: 100%}
  100% {width: 0%}
`;
const ProgressBar = styled.div`
  width: 90vw;
  max-width: min(640px, 60vh);
  height: 2rem;
  position: absolute;
  left: 0%;
  bottom: 0%;
  border-radius: 6px;
  overflow: hidden;

  .red {
    position: absolute;
    width: 0%;
    height: 100%;
    background-color: #ff6969;
    animation: ${recordAnimation} 5s linear;
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.75;
  }
`;

const FinishedPageContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .text-content {
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    bottom: 50%;

    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    ${fluidFont(22, 24)}
    line-height: 120%;
  }
  .button-wrapper {
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    bottom: 5%;
  }
`;

const QuitButton = styled(PrimaryButtonStyle)`
  position: absolute;
  width: 80%;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 640px;
  z-index: 100;
`;

const UploadVideoContent = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: white;
`;

const ErrorContent = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 99;
  background-color: white;
`;

let timeOut: ReturnType<typeof setTimeout>;
const Record: FunctionalComponent = () => {
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);

  const [pageState, updatePageState] = useState<record>(record.sample);
  const [isSetupCamera, updateIsSetupCamera] = useState(false);
  const [isModelLoaded, updateLoadedModel] = useState(false);
  const [faceModel] = useState(new FaceDectionMediapipe());
  const signLanguageSelection = useRecoilValue(signLaguageSelectionAtom);
  const signSelectionList = useRecoilValue(signSelectionListAtom);
  const [signingSession, updateSigningSession] =
    useRecoilState(signingSessionAtom);
  const [isQuitPopup, updateIsQuitPopup] = useState(false);
  const CameraRef = useRef<CameraComponentsHandle>(null);
  const error = useRecoilValue(errorAtom);

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );

  useEffect(() => {
    console.log({ signSelectionList, signingSession });
    console.log(signSelectionList[signingSession.sessionProgress]);
    faceModel.loadModel().then((res: any) => {
      if (res) updateLoadedModel(true);
    });
    if (signingSession.sessionProgress === signingSession.sessionSelect)
      updatePageState(5);
  }, []);

  const nextState = () => {
    if (isQuitPopup) return;
    updatePageState(pageState + 1);
  };

  const handeUpdatePageState = (state: record) => {
    if (isQuitPopup) return;
    console.log("change state: ", state);
    switch (state) {
      case record.uploading:
        if (pageState === record.recording) {
          updatePageState(state);
        }
        break;
      default:
        updatePageState(state);
        break;
    }
  };

  const handleClickQuit = () => {
    updateIsQuitPopup(true);
    clearTimeout(timeOut);
  };

  useEffect(() => {
    if (pageState === record.uploaded) {
      if (signingSession.sessionProgress === signingSession.sessionSelect - 1) {
        updatePageState(record.finish);
      } else {
        timeOut = setTimeout(() => {
          updateSigningSession({
            ...signingSession,
            sessionProgress: signingSession.sessionProgress + 1,
          });
          updatePageState(record.sample);
        }, 2000);
      }
    } else if (pageState === record.getready) {
      timeOut = setTimeout(() => {
        nextState();
      }, 2000);
    } else if (pageState === record.sample) {
      updatePerfectFace(false);
    }
  }, [pageState]);

  const handleSetupCamera = () => {
    updateIsSetupCamera(true);
  };

  const textDisplay = (item: signSelectionObjectT) => {
    let textDis = definitionToString(item.text.en);
    let translationTextDis = definitionToString(
      item.text[signLanguageList[signLanguageSelection].translation]
    );
    if (translationTextDis && translationTextDis !== textDis) {
      textDis = `${textDis} (${translationTextDis})`;
    }
    return textDis;
  };

  const [perfectFace, updatePerfectFace] = useState<boolean>(false);

  const handleStart = () => {
    updatePerfectFace(true);
  };

  useEffect(() => {
    if (perfectFace) {
      if (pageState !== record.sample) {
        nextState();
      } else {
        updatePerfectFace(false);
      }
    }
  }, [perfectFace]);

  const handleChooseSignLanguage = () => {
    route("/collect/choose-sign-language");
  };
  const handleChooseSigningSession = () => {
    route("/collect/choose-session");
  };

  const handleResume = () => {
    CameraRef.current?.cleanupBuffer();
    updateIsQuitPopup(false);
    updatePageState(record.sample);
    if (typeof window !== "undefined" && pageState === record.recording)
      window.location.reload();
  };

  return (
    <Fragment>
      {isModelLoaded && pageState !== record.finish && (
        <Fragment>
          <Header>
            <Number>{signingSession.sessionProgress + 1}</Number>
            <Title>
              {signSelectionList &&
                signingSession &&
                textDisplay(signSelectionList[signingSession.sessionProgress])}
            </Title>
            <div></div>
          </Header>
          <HeaderNote>
            <TitleNote>{language.pleaseSignOnlyOnce}</TitleNote>
          </HeaderNote>
          <HeaderNote>
            <TitleNote>{language.pleaseCopyTheSampleVideo}</TitleNote>
          </HeaderNote>
          {pageState === record.sample && (
            <CameraContainer>
              <Sample
                nextState={nextState}
                handeUpdatePageState={handeUpdatePageState}
                videoUrl={
                  signSelectionList[signingSession.sessionProgress].videoUrl
                }
              />
            </CameraContainer>
          )}

          {error !== errorE.IDLE && (
            <Fragment>
              <ErrorContent>
                {error === errorE.CAMERA_PERMISSION && (
                  <p>
                    Camera permission denied. <br />
                    <br /> Please allow browser to access camera.
                  </p>
                )}
                {error === errorE.CAMERA_NOT_DETECT && (
                  <p>
                    Camera not detected. <br /> <br /> Please connect your
                    camera
                  </p>
                )}
              </ErrorContent>
            </Fragment>
          )}

          <Fragment>
            <CameraContainer>
              <CameraWrapper>
                <Camera
                  handleSetupCamera={handleSetupCamera}
                  pageState={pageState}
                  faceModel={faceModel}
                  handleStart={handleStart}
                  handeUpdatePageState={handeUpdatePageState}
                  signId={
                    signSelectionList[signingSession.sessionProgress].signId
                  }
                  signLanguageId={
                    signSelectionList[signingSession.sessionProgress]
                      .signLanguageId
                  }
                  isQuitPopup={isQuitPopup}
                />
                {pageState === record.getready && (
                  <Fragment>
                    <Aligned />
                    <AlearText>{language.readyText}</AlearText>
                  </Fragment>
                )}
                {(pageState === record.recording ||
                  pageState === record.recorded) && (
                  <ProgressBar>
                    <div className="bg"></div>
                    <div className="red"></div>
                  </ProgressBar>
                )}
                {pageState === record.recorded && (
                  <AlearText>GOOD JOB!</AlearText>
                )}
              </CameraWrapper>
            </CameraContainer>
            {(pageState === record.uploading ||
              pageState === record.uploaded) && (
              <UploadVideoContent>
                <p>{language.uploadingText}</p>
                <div style={{ width: "30%", height: "30%" }}>
                  <Loader />
                </div>
              </UploadVideoContent>
            )}
          </Fragment>
        </Fragment>
      )}
      {pageState === record.finish && (
        <Fragment>
          <FinishedPageContent>
            <div className="text-content">
              <p>
                {signingSession.sessionProgress + 1} {language.finish.label}
              </p>
              <p>{language.finish.finishText}</p>
            </div>
            <div className="button-wrapper">
              <SecondaryButtonStyle
                style={{ width: "100%" }}
                onClick={handleChooseSignLanguage}
              >
                {language.finish.chooseOtherSignButtonText}
              </SecondaryButtonStyle>
              <PrimaryButtonStyle
                style={{ width: "100%" }}
                onClick={handleChooseSigningSession}
              >
                {language.finish.chooseOtherSessionButtonText}
              </PrimaryButtonStyle>
            </div>
          </FinishedPageContent>
        </Fragment>
      )}
      {signingSession.sessionProgress > -1 && pageState !== record.finish && (
        <Fragment>
          <QuitButton onClick={handleClickQuit}>
            {language.quitButtonText}
          </QuitButton>
        </Fragment>
      )}
      <QuitPopup isQuitPopup={isQuitPopup} handleResume={handleResume} />
    </Fragment>
  );
};

interface Ishow {
  isShow?: boolean;
}
const PopupBg = styled.div<Ishow>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: ${(props) => (props.isShow ? 0.6 : 0)};
  z-index: ${(props) => (props.isShow ? 99 : -99)};

  transition: all 0.3s;
`;
const PopupRec = styled.div<Ishow>`
  width: 80vw;
  height: 30vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: ${(props) =>
    props.isShow ? "translate(-50%, -50%)" : "translate(-50%, 120%)"};
  z-index: ${(props) => (props.isShow ? 99 : -50)};
  opacity: ${(props) => (props.isShow ? 1 : 0)};
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: #333333;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  transition: all 0.3s;

  h3 {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    margin: 1.5rem 1rem;
    ${fluidFont(16, 18)}
  }
  p {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    color: #828282;
    margin: 0 1rem;
    ${fluidFont(14, 16)}
  }
  div {
    position: absolute;
    bottom: 10%;
    right: 5%;
    button {
      border: none;
      padding: 0.3rem 0.6rem;
      margin: 0 0.3rem;
      border-radius: 6px;
      background: transparent;

      color: #68aee8;

      font-family: "Google Sans";
      font-style: normal;
      font-weight: 500;
      ${fluidFont(14, 16)}
      text-align: center;
      transition: all 0.2s;
      letter-spacing: 0.1px;
      cursor: pointer;
      :hover {
        background-color: #68aee8;
        color: white;
      }
    }
  }
`;

type QuitPopupType = {
  isQuitPopup: boolean;
  handleResume: () => void;
};
const QuitPopup: FunctionalComponent<QuitPopupType> = (
  props: QuitPopupType
) => {
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );

  const handleClickResume = () => {
    props.handleResume();
  };
  const handleClickQuit = () => {
    route("/collect/choose-sign-language");
  };
  return (
    <Fragment>
      <PopupBg isShow={props.isQuitPopup} />
      <PopupRec isShow={props.isQuitPopup}>
        <h3>{language.popup.title}</h3>
        <p>{language.popup.text}</p>
        <div>
          <button onClick={handleClickResume}>
            {language.popup.resumeButtonText}
          </button>
          <button onClick={handleClickQuit}>
            {language.popup.quitButtonText}
          </button>
        </div>
      </PopupRec>
    </Fragment>
  );
};

export default Record;
