import { Fragment, FunctionalComponent, h } from "preact";
import { useEffect, useRef } from "preact/hooks";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { record } from ".";

const SampleWrapper = styled.div`
  width: 90%;
  max-width: min(640px, 60vh);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
    border: 4px solid #333333;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #333;
  }
`;

interface SampleI {
  videoUrl: string | undefined;
  nextState: () => void;
  handeUpdatePageState: (pageState: number) => void;
}
const Sample: FunctionalComponent<SampleI> = (props: SampleI) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef) {
      console.log(videoRef);
      videoRef.current?.addEventListener("ended", () => {
        console.log("ended");
        props.nextState();
        props.handeUpdatePageState(record.positioning);
      });
    }
  }, [videoRef]);
  return (
    <SampleWrapper>
      <video
        src={props.videoUrl}
        ref={videoRef}
        autoPlay
        muted
        playsInline
      ></video>
    </SampleWrapper>
  );
};

export default Sample;
