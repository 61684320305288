import { h } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import styled from "styled-components";
import { fluidFont } from "../../../style/globalStyle";
import { useRecoilValue } from "recoil";
import { webLanguageSelector } from "../../../recoil/global";
import accessNestedObj from "../../../utils/object";

const PAGE_PATH: string =
  "collect.consent.terms-and-conditions-and-privacy-policy";

const Container = styled.section`
  width: 100%;
  height: fit-content;
  margin-bottom: 50%;
  text-align: left;
  padding: 0 1rem;

  ${fluidFont(14, 18)}

  h3 {
    font-weight: 700px;
    margin: 0;
    ${fluidFont(14, 18)}
  }
  h4 {
    margin: 0.6rem 0;
    ${fluidFont(14, 18)}
  }
  p {
    font-weight: 400px;
    ${fluidFont(14, 18)}
  }
`;

const PreLine = styled.span`
  white-space: pre-line;
`;

const TermsAndConditionsContent = () => {
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);
  const [WEBSITE_URL, updateWebsiteUrl] = useState("");

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      updateWebsiteUrl(window.location.origin);
    }
  }, []);

  console.log(language);

  return (
    <Container>
      <h3>{language.paragraph["terms-and-condition"].title}</h3>
      <p>
        <PreLine
          dangerouslySetInnerHTML={{
            __html: language.paragraph["terms-and-condition"].text(WEBSITE_URL),
          }}
        />
        <br />
        <br />
        <h4>{language.paragraph["user-content"].title}</h4>
        {language.paragraph["user-content"].text}
        <br />
        <br />
        <h4>{language.paragraph["backups"].title}</h4>
        {language.paragraph["backups"].text}
        <br />
        <br />
        <h4>{language.paragraph["links-to-other-websites"].title}</h4>
        {language.paragraph["links-to-other-websites"].text}
        <br />
        <br />
        <h4>{language.paragraph["limitation-of-liability"].title}</h4>
        {language.paragraph["limitation-of-liability"].text}
        <br />
        <br />
        <h4>{language.paragraph["changes-and-amendments"].title}</h4>
        {language.paragraph["changes-and-amendments"].text}
        <br />
        <br />
        <h4>{language.paragraph["acceptance-of-these-terms"].title}</h4>
        {language.paragraph["acceptance-of-these-terms"].text}
        <br />
        <br />
        <h4>{language.paragraph["contacting-us"].title}</h4>
        {language.paragraph["contacting-us"].text}
        <br />
        <br />
        {language.paragraph["last-updated"]}
        <br />
        <br />
        <h3>{language.paragraph["privacy-policies"].title}</h3>
        <PreLine>{language.paragraph["privacy-policies"].text}</PreLine>
        <br />
        <br />
        <h4>
          {language.paragraph["automatic-collection-of-information"].title}
        </h4>
        {language.paragraph["automatic-collection-of-information"].text}
        <br />
        <br />
        <h4>
          {language.paragraph["collection-of-personal-information"].title}
        </h4>
        <PreLine>
          {language.paragraph["collection-of-personal-information"].text}
        </PreLine>
        <br />
        <br />
        <h4>
          {
            language.paragraph["use-and-processing-of-collected-information"]
              .title
          }
        </h4>
        <PreLine
          dangerouslySetInnerHTML={{
            __html:
              language.paragraph["use-and-processing-of-collected-information"]
                .text,
          }}
        />
        <br />
        <br />
        <h4>{language.paragraph["disclosure-of-information"].title}</h4>
        <PreLine>
          {language.paragraph["disclosure-of-information"].text}
        </PreLine>
        <br />
        <br />
        <h4>{language.paragraph["retention-of-information"].title}</h4>
        {language.paragraph["retention-of-information"].text}
        <br />
        <br />
        <h4>{language.paragraph["the-rights-of-users"].title}</h4>
        {language.paragraph["the-rights-of-users"].text}
        <br />
        <br />
        <h4>{language.paragraph["privacy-of-children"].title}</h4>
        {language.paragraph["privacy-of-children"].text}
        <br />
        <br />
        <h4>{language.paragraph["do-not-track-signals"].title}</h4>
        {language.paragraph["do-not-track-signals"].text}
        <br />
        <br />
        <h4>{language.paragraph["links-to-other-websites-2"].title}</h4>
        {language.paragraph["links-to-other-websites-2"].text}
        <br />
        <br />
        <h4>{language.paragraph["information-security"].title}</h4>
        {language.paragraph["information-security"].text}
        <br />
        <br />
        <h4>{language.paragraph["data-breach"].title}</h4>
        {language.paragraph["data-breach"].text}
        <br />
        <br />
        <h4>{language.paragraph["changes-and-amendments-2"].title}</h4>
        {language.paragraph["changes-and-amendments-2"].text}
        <br />
        <br />
        <h4>{language.paragraph["acceptance-of-this-policy"].title}</h4>
        {language.paragraph["acceptance-of-this-policy"].text}
        <br />
        <br />
        <h4>{language.paragraph["contacting-us-2"].title}</h4>
        {language.paragraph["contacting-us-2"].text}
        <br />
        <br />
        {language.paragraph["last-updated"]}
        <br />
      </p>
    </Container>
  );
};

export default TermsAndConditionsContent;
