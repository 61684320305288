import { Fragment, FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { useMemo } from "preact/hooks";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  signLaguageSelectionAtom,
  signLanguageSelector,
  webLanguageSelector,
} from "../../../recoil/global";
import {
  SelectionTitle,
  SelectionButton,
  SelectionWrapper,
} from "../../../style/globalStyle";
import accessNestedObj from "../../../utils/object";

const PAGE_PATH: string = "collect.chooseSignLanguage";

const ChooseSignLanguage: FunctionalComponent = () => {
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);
  const signLanguageList = useRecoilValue(signLanguageSelector);

  const [, updateSignLanguageSelecion] = useRecoilState(
    signLaguageSelectionAtom
  );

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );

  const handleSelectSignLanguage = (input: number) => {
    updateSignLanguageSelecion(input);
    route("/collect/consent");
  };

  return (
    <Fragment>
      <SelectionTitle>{language.title}</SelectionTitle>
      <SelectionWrapper>
        {signLanguageList.map((item, index) => {
          return (
            <SelectionButton onClick={() => handleSelectSignLanguage(index)}>
              {item.title}
            </SelectionButton>
          );
        })}
      </SelectionWrapper>
    </Fragment>
  );
};

export default ChooseSignLanguage;
