import axios from "axios";
import { frameBufferType } from "../components/camera";
import JSZip from "./jszip.min.js";

export const getDataFromApi = (url: string) => {
  return new Promise(async (resolve, reject) => {
    axios
      .get(url)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
