export const definitionToString = (
  input: { def: string; pos: string }[] | string
) => {
  if (typeof input === "string") {
    return input;
  } else {
    if (input && input.length > 0) {
      return input.map(({ def }) => def).join(", ");
    }
  }
  return null;
};
