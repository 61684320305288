import { h } from "preact";
import styled, { keyframes } from "styled-components";

const ldsAnimation = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderStyle = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #9bd9f4;
    border-radius: 50%;
    animation: ${ldsAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #9bd9f4 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Loader = () => {
  return (
    <LoaderStyle>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoaderStyle>
  );
};

export default Loader;
