const englishMapping = {
  collect: {
    chooseSignLanguage: {
      title: "Choose sign language",
      signLanguage: {
        HKSL: "Hong Kong Sign Language (HKSL)",
        JSL: "Japanese Sign Language (JSL)",
        YSL: "Yangon Sign Language (YSL)",
      },
    },
    consent: {
      "terms-and-conditions-and-privacy-policy": {
        title: "Terms and Conditions and Privacy Policy",
        longVideoLink: "Terms and Conditions Video in JSL",
        checkboxText: "I agree to the Terms of Service and Privacy Policy",
        buttonText: "Submit",
        quoteBlock: "",
        paragraph: {
          "terms-and-condition": {
            title: "Terms and conditions",
            text: (url: string) =>
              englishConsent.termsAndConditionEnglishMapping(url),
          },
          "user-content": {
            title: "User content",
            text: `We do not own any data, information or material ("Content") that you
          submit on the Website in the course of using the Service. You shall have
          sole responsibility for the accuracy, quality, integrity, legality,
          reliability, appropriateness, and intellectual property ownership or
          right to use of all submitted Content. We may, but have no obligation
          to, monitor and review Content on the Website submitted or created using
          our Services by you. You grant us permission to access, copy,
          distribute, store, transmit, reformat, display and perform the Content
          of your user account solely as required for the purpose of providing the
          Services to you. Without limiting any of those representations or
          warranties, we have the right, though not the obligation, to, in our own
          sole discretion, refuse or remove any Content that, in our reasonable
          opinion, violates any of our policies or is in any way harmful or
          objectionable. You also grant us the license to use, reproduce, adapt,
          modify, publish or distribute the Content created by you or stored in
          your user account for commercial, marketing or any similar purpose.`,
          },
          backups: {
            title: "Backups",
            text: "We perform regular backups of the Website and Content and will do our best to ensure completeness and accuracy of these backups. In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.",
          },
          "links-to-other-websites": {
            title: "Links to other websites",
            text: `Although this Website may link to other websites, we are not, directly
          or indirectly, implying any approval, association, sponsorship,
          endorsement, or affiliation with any linked website, unless specifically
          stated herein. We are not responsible for examining or evaluating, and
          we do not warrant the offerings of, any businesses or individuals or the
          content of their websites. We do not assume any responsibility or
          liability for the actions, products, services, and content of any other
          third parties. You should carefully review the legal statements and
          other conditions of use of any website which you access through a link
          from this Website. Your linking to any other off-site websites is at
          your own risk.`,
          },
          "limitation-of-liability": {
            title: "Limitation of liability",
            text: `To the fullest extent permitted by applicable law, in no event will
          Website Operator, its affiliates, directors, officers, employees,
          agents, suppliers or licensors be liable to any person for any indirect,
          incidental, special, punitive, cover or consequential damages
          (including, without limitation, damages for lost profits, revenue,
          sales, goodwill, use of content, impact on business, business
          interruption, loss of anticipated savings, loss of business opportunity)
          however caused, under any theory of liability, including, without
          limitation, contract, tort, warranty, breach of statutory duty,
          negligence or otherwise, even if the liable party has been advised as to
          the possibility of such damages or could have foreseen such damages. To
          the maximum extent permitted by applicable law, the aggregate liability
          of Website Operator and its affiliates, officers, employees, agents,
          suppliers and licensors relating to the services will be limited to an
          amount greater of one dollar or any amounts actually paid in cash by you
          to Website Operator for the prior one month period prior to the first
          event or occurrence giving rise to such liability. The limitations and
          exclusions also apply if this remedy does not fully compensate you for
          any losses or fails of its essential purpose.`,
          },
          "changes-and-amendments": {
            title: "Changes and amendments",
            text: `We reserve the right to modify this Agreement or its policies relating
          to the Website or Services at any time, effective upon posting of an
          updated version of this Agreement on the Website. When we do, we will
          revise the updated date at the bottom of this page. Continued use of the
          Website after any such changes shall constitute your consent to such
          changes.`,
          },
          "acceptance-of-these-terms": {
            title: "Acceptance of these terms",
            text: `You acknowledge that you have read this Agreement and agree to all its
          terms and conditions. By using the Website or its Services you agree to
          be bound by this Agreement. If you do not agree to abide by the terms of
          this Agreement, you are not authorized to use or access the Website and
          its Services.`,
          },
          "contacting-us": {
            title: "Contacting us",
            text: `If you would like to contact us to understand more about this Agreement
          or wish to contact us concerning any matter relating to it, you may send
          an email to contact@bit.studio`,
          },
          "last-updated": "This document was last updated on July 17, 2020",
          "privacy-policies": {
            title: "Privacy Policies",
            text: `This privacy policy ("Policy") describes how Website Operator ("Website Operator", "we", "us" or "our") collects, protects and uses the personally identifiable information ("Personal Information") you ("User", "you" or "your") may provide on the bit-sign-lang-data-collector.web.app website and any of its products or services (collectively, "Website" or "Services").
          It also describes the choices available to you regarding our use of your Personal Information and how you can access and update this information. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.

          It also describes the choices available to you regarding our use of your
          Personal Information and how you can access and update this information.
          This Policy does not apply to the practices of companies that we do not
          own or control, or to individuals that we do not employ or manage.`,
          },
          "automatic-collection-of-information": {
            title: "Automatic collection of information",
            text: `Our top priority is customer data security and, as such, we exercise the
          no logs policy. We may process only minimal user data, only as much as
          it is absolutely necessary to maintain the Website or Services.
          Information collected automatically is used only to identify potential
          cases of abuse and establish statistical information regarding Website
          usage. This statistical information is not otherwise aggregated in such
          a way that would identify any particular user of the system.`,
          },
          "collection-of-personal-information": {
            title: "Collection of personal information",
            text: `You can visit the Website without telling us who you are or revealing
          any information by which someone could identify you as a specific,
          identifiable individual. If, however, you wish to use some of the
          Website's features, you may be asked to provide certain Personal
          Information (for example, your name and e-mail address). We receive and
          store any information you knowingly provide to us when you publish
          content, or fill any online forms on the Website. When required, this
          information may include any materials you willingly submit to us such as
          articles, images, feedback, etc.

          You can choose not to provide us with your Personal Information, but
          then you may not be able to take advantage of some of the Website's
          features. Users who are uncertain about what information is mandatory
          are welcome to contact us.`,
          },
          "use-and-processing-of-collected-information": {
            title: "Use and processing of collected information",
            text: `In order to make our Website and Services available to you, or to meet a legal obligation, we need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Some of the information we collect is directly from you via our Website. However, we may also collect Personal Information about you from other sources. Any of the information we collect from you may be used for the following purposes:
            <ul>
              <li>Training machine learning models to detect sign languages</li>
              <li>Improve user experience</li>
              <li>Run and operate our Website and Services</li>
            </ul>
          Processing your Personal Information depends on how you interact with our Website, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; this, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.

          Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.`,
          },
          "disclosure-of-information": {
            title: "Disclosure of information",
            text: `Depending on the requested Services or as necessary to complete any transaction or provide any service you have requested, we may share your information with your consent with our trusted third parties that work with us, any other affiliates and subsidiaries we rely upon to assist in the operation of the Website and Services available to you. We do not share Personal Information with unaffiliated third parties. These service providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. We may share your Personal Information for these purposes only with third parties whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. These third parties are given Personal Information they need only in order to perform their designated functions, and we do not authorize them to use or disclose Personal Information for their own marketing or other purposes.

          We will disclose any Personal Information we collect, use or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.`,
          },
          "retention-of-information": {
            title: "Retention of information",
            text: "We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.",
          },
          "the-rights-of-users": {
            title: "The rights of users",
            text: "You may exercise certain rights regarding your information processed by us. In particular, you have the right to do the following: (i) you have the right to withdraw consent where you have previously given your consent to the processing of your information; (ii) you have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent; (iii) you have the right to learn if information is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the information undergoing processing; (iv) you have the right to verify the accuracy of your information and ask for it to be updated or corrected; (v) you have the right, under certain circumstances, to restrict the processing of your information, in which case, we will not process your information for any purpose other than storing it; (vi) you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us; (vii) you have the right to receive your information in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that your information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof.",
          },
          "privacy-of-children": {
            title: "Privacy of children",
            text: "We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through our Website or Service. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through our Website or Service without their permission. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through our Website or Service, please contact us. You must also be old enough to consent to the processing of your Personal Information in your country (in some countries we may allow your parent or guardian to do so on your behalf).",
          },
          "do-not-track-signals": {
            title: "Do Not Track signals",
            text: "Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. Our Website does not track its visitors over time and across third party websites. However, some third party sites may keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you.",
          },
          "links-to-other-websites-2": {
            title: "Links to other websites",
            text: "Our Website contains links to other websites that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other websites or third parties. We encourage you to be aware when you leave our Website and to read the privacy statements of each and every website that may collect Personal Information.",
          },
          "information-security": {
            title: "Information security",
            text: "We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed. Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and our Website cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.",
          },
          "data-breach": {
            title: "Data breach",
            text: "In the event we become aware that the security of the Website has been compromised or users Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will post a notice on the Website.",
          },
          "changes-and-amendments-2": {
            title: "Changes and amendments",
            text: "We may update this Privacy Policy from time to time in our discretion and will notify you of any material changes to the way in which we treat Personal Information. When changes are made, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways in our discretion, such as through contact information you have provided. Any updated version of this Privacy Policy will be effective immediately upon the posting of the revised Privacy Policy unless otherwise specified. Your continued use of the Website or Services after the effective date of the revised Privacy Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.",
          },
          "acceptance-of-this-policy": {
            title: "Acceptance of this policy",
            text: "You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.",
          },
          "contacting-us-2": {
            title: "Contacting us",
            text: "If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to contact@bit.studio",
          },
        },
      },
      "rights-to-public-domain": {
        title: "Dedication of Rights to the public domain",
        longVideoLink: "Dedication of Rights to the Public Domain Video in JSL",
        rejectButtonText: "No, thanks",
        acceptButtonText: "Yes, please",
        paragraph: {
          "dedication-of-rights-to-the-public-domain": {
            title: "Dedication of Rights to the public domain",
            text: (url: string) =>
              englishConsent.rightsToPublicDomainEnglishMapping(url),
          },
          "requirements-and-registration": {
            title: "Requirements and Registration",
            text: "You may use the Service only if you can form a binding contract with Sign Language Data Collector, and only in compliance with these Terms and all applicable laws, rules, and regulations. You may be required to register with us in order to access and use certain features of the Service. If you choose to register for the Service, you agree to provide and maintain true, accurate, and current information as prompted by the Service's registration form. Registration data and certain other information about you are governed by our Privacy Policy. If you are under 16 years old, you may use the Service only with the approval of your parent, guardian, or teacher.",
          },
          "uploading-content": {
            title: "Uploading Content",
            text: `By uploading Content to the Website, you grant Sign Language Data Collector, and our licensors, affiliates and partners, the license under <a href="https://creativecommons.org/publicdomain/zero/1.0/">CC0 1.0 Universal (CC0 1.0)<br />Public Domain Dedication</a>. You grant an irrevocable, worldwide, non-exclusive and royalty-free licence to use, download, copy, modify or adapt, the Content (in whole or in part) for any purpose, both commercial and non-commercial. In the case of Audio Content, this includes, without limitation, the right to play the Audio in public and to synchronise the Audio to video content. For the avoidance of doubt, this licence includes the right of Sign Language Data Collector to distribute the Content under the Sign Language Data Collector License, the Sign Language Data Collector Audio License, or any other license offered by Sign Language Data Collector from time to time, including via the Sign Language Data Collector API. You acknowledge and confirm that your Content will be made available to the public on and through the Service for personal and commercial use of third parties subject to these Terms without providing you attribution or compensation.

            You are solely responsible for the Content you upload. You warrant that:
            <ul>
              <li>you own all proprietary rights in the Content you upload to the
                Website and that the Content does not infringe the copyright,
                property right, trademark or other applicable rights of any third
                parties;
              </li>
              <li>in the case of Audio Content, use of the Audio as contemplated by
                these Terms shall not infringe any rights in any underlying musical
                or literary work subsisting in the Audio; and
              </li>
              <li>you have obtained a non-exclusive, perpetual, irrevocable,
                worldwide, and royalty-free Model and/or Property Release, and/or
                any other permission necessary concerning the use of this work for
                any purpose, without any conditions, unless such conditions are
                required by law.
              </li>
            </ul>
            You accept that even though we do our best to prevent it from happening, Sign Language Data Collector cannot be held responsible for the acts or omissions of its users, including any misuse or abuse of any Content you upload.

            We also reserve the right to remove any Content at any time and for any reason, including if we believe it's defective, of poor quality, or in violation of these Terms. Sign Language Data Collector has adopted a policy of terminating, inappropriate circumstances, users who are deemed to be repeat infringers.`,
          },
          "use-of-the-service": {
            title: "Use of the Service",
            text: `In connection with your use of the Service you must not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. The technology and software underlying the Service or distributed in connection therewith is the property of Sign Language Data Collector and our licensors, affiliates and partners and you are granted no license in respect of that Software. You agree not to copy, modify, create a derivative work from, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in such technology or software. Any rights not expressly granted herein are reserved by Sign Language Data Collector. Large scale or systematic copying of Content, including using any of the methods referred to above, is prohibited except as expressly authorized by Sign Language Data Collector.

            This applies to all Content, including Content made available as part of the public domain. The Service is protected by copyright as a collective work and/or compilation, pursuant to copyright laws, international conventions, and other intellectual property laws.`,
          },
          "notice-and-takedown-policy": {
            title: "Notice and Takedown Policy",
            text: `Sign Language Data Collector respects the right of creatives. Accordingly, it is our policy to respond to alleged infringement notices promptly. If you believe that your copyright, trademark, or other right has been infringed by Content that is accessible via the Service, we ask that you write to us and provide the following information:
            <ul>
              <li>Identification of the copyright work, trade mark or other right you claim has been infringed;</li>
              <li>Identification of the material that is claimed to be infringing, including a URL link to where it appears on the Service;</li>
              <li>Your contact details, such as your email address;</li>
              <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorised by the copyright / trademark / other right owner, its agent, or law; and5.A declaration that the above information is accurate and that you are the rights owner (or authorised to act on their behalf).</li>
            </ul>Please submit the information to by email to contact@bit.studio

            The preceding does not constitute legal advice. It may be advisable to contact an attorney regarding your rights and obligations under applicable laws.`,
          },
          termination: {
            title: "Termination",
            text: "We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Website will immediately cease.",
          },
          "indemnification-for-breach-of-terms": {
            title: "Indemnification for breach of Terms",
            text: "You agree to indemnify and hold harmless Sign Language Data Collector from and against any and all loss, expenses, damages, and costs, including without limitation reasonable attorneys fees, resulting, whether directly or indirectly, from your violation of the Terms. You also agree to indemnify and hold harmless Sign Language Data Collector from and against any and all claims brought by third parties arising out of your use of the Website.",
          },
          "warranty-and-liability": {
            title: "Warranty and liability",
            text: 'The website and its content are provided "as is". We offer no warranty, explicit or implied, regarding any content, the website, the accuracy of any information, or any rights or licenses under this agreement including, without limitation, any implied warranties of merchantability or fitness for a particular purpose. Sign Language Data Collector does not represent or warrant that the website or its content will meet your requirements or that their use will be uninterrupted or error free. Sign Language Data Collector shall not be liable to you or to any other person or entity for any general, punitive, special, indirect, consequential or incidental damages, or lost profits or any other damages, costs or losses arising out of your use of the website or its content.',
          },
        },
      },
    },
    chooseSession: {
      title: "Choose Session",
      signsText: "Signs",
      minsText: "mins",
    },
    "sign-list": {
      title: "Let's sign these words!",
      changeButtonText: "Change",
      backButtonText: "Back",
      startButtonText: "Start",
    },
    record: {
      pleaseSignOnlyOnce: "Please sign only once (do not repeat)",
      pleaseCopyTheSampleVideo: "Please copy the sample video",
      tooCloseText: "TOO CLOSE",
      tooFarText: "TOO FAR",
      perfectText: "PERFECT",
      readyText: "GET READY TO SIGN",
      uploadingText: "Uploading video content",
      quitButtonText: "Quit",
      popup: {
        title: "Quit this session and start over?",
        text: "Don't worry, we've already collected the previous signs you finished.",
        quitButtonText: "Quit",
        resumeButtonText: "Resume",
      },
      finish: {
        finishText: "complete! 🎉",
        label: "signs",
        chooseOtherSignButtonText: "Choose other sign language",
        chooseOtherSessionButtonText: "Choose other signing session",
      },
    },
  },
};

const englishConsent = {
  termsAndConditionEnglishMapping: (url: string) => {
    return `Welcome to Sign Language Data Collector!

    These terms and conditions ("Terms", "Agreement") outline the rules and regulations for the use of Bit Studio's Website, located at <a href={${url}}>${url}</a>.

    By accessing this website we assume you accept these terms and conditions. Do not continue to use Sign Language Data Collector if you do not agree to take all of the terms and conditions stated on this page.

    The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "User", "You" and "Your" refers to you. "The Company", "Website Operator", "We", "Our" and "Us", refers to our Company. This Agreement sets forth the general terms and conditions of your use of the <a href={${url}}>${url}</a> website and any of its products or services (collectively, "Website" or "Services").`;
  },
  rightsToPublicDomainEnglishMapping: (url: string) => {
    return `The following is a legal agreement between you ("you" or "User/s") and the owners and operators ("we", "us", or "Sign Language Data Collector") of the site at <a href={${url}}>${url}</a> (the "Website") and all related websites, software, mobile apps, plug-ins and other services that we provide (together, the "Service"). Your use of the Service, and our provision of the Service to you, constitutes an agreement by you and Sign Language Data Collector to be bound by the terms and conditions in these Terms of Service ("Terms").

    "Content" shall refer collectively to all Images, Videos and Audio uploaded to Sign Language Data Collector and made available under the Sign Language Data Collector License and the Sign Language Data Collector Audio License. "Image/s" includes photographs, vectors, drawings, and illustrations. "Video" refers to any moving images, animations, films, or other audio/visual representations. "Audio" refers to any music or other audio file.

    We reserve the right, at our sole discretion, to change or modify portions of these Terms at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date these Terms were last revised. Any such changes will become effective immediately. Your continued use of the Service after the date any such changes become effective constitutes your acceptance of the new Terms.`;
  },
};

export default englishMapping;
