import styled from "styled-components";

export const fluidFont = (min: number, max: number) => {
  return `font-size: calc(${min}px + (${max} - ${min}) * ((100vw - 300px) / (1000 - 300)));`;
};

export const Title = styled.h1`
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  ${fluidFont(16, 20)}
  line-height: 100%;
  /* identical to box height, or 16px */

  text-align: center;

  /* Gray 1 */

  color: #333333;
  padding-top: 5%;
`;

export const QuoteBlock = styled.div`
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  ${fluidFont(16, 20)}
  // line-height: 100%;
  /* identical to box height, or 16px */

  text-align: center;
  font-style: italic;
  padding: 1.5rem 1rem;

  margin-bottom: 1rem;

  /* Gray 2 */

  color: #737373;
  background-color: #f3f3f3;
`;

export const SelectionTitle = styled(Title)`
  padding-top: 20%;
`;

export const SelectionWrapper = styled.div`
  width: 90%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: auto;
`;

type PrimaryButtonStyleT = {
  disabled?: boolean;
};
export const PrimaryButtonStyle = styled.button<PrimaryButtonStyleT>`
  background: ${(props) => (props.disabled ? "#E0E0E0" : "#9bd9f4")};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2rem;

  border: none;
  padding: 0.8rem;
  width: 50%;

  margin: 1rem auto;

  ${fluidFont(14, 18)}
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${(props) => (props.disabled ? "#FFFFFF" : "#333333")};

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  transition: all 0.2s;

  :hover {
    color: white;
    background-color: #68aee8;
  }
`;

export const SecondaryButtonStyle = styled(PrimaryButtonStyle)`
  background: ${(props) => (props.disabled ? "#E0E0E0" : "#FFFFFF")};

  :hover {
    color: white;
    background-color: #999;
  }
`;

export const SelectionButton = styled.button`
  width: 100%;
  height: 4rem;

  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: none;

  ${fluidFont(16, 20)}
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 500;
  color: #68aee8;

  cursor: pointer;
`;
