import { h } from "preact";
import { useEffect, useState, useMemo } from "preact/hooks";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { fluidFont } from "../../../style/globalStyle";
import { webLanguageSelector } from "../../../recoil/global";
import accessNestedObj from "../../../utils/object";

const PAGE_PATH: string = "collect.consent.rights-to-public-domain";

const Container = styled.section`
  width: 100%;
  height: fit-content;
  margin-bottom: 50%;
  text-align: left;
  padding: 0 1rem;

  z-index: -10;

  ${fluidFont(14, 18)}

  h3 {
    font-weight: 700px;
    margin: 0;
    ${fluidFont(14, 18)}
  }
  h4 {
    margin: 0.6rem 0;
    ${fluidFont(14, 18)}
  }
  p {
    font-weight: 400px;
    ${fluidFont(14, 18)}
  }
`;

const PreLine = styled.span`
  white-space: pre-line;
`;

const RightsToPublicDomain = () => {
  const webLanguageSelectorValue = useRecoilValue(webLanguageSelector);

  const [WEBSITE_URL, updateWebsiteUrl] = useState("");

  const language = useMemo(
    () => accessNestedObj(webLanguageSelectorValue, PAGE_PATH),
    [webLanguageSelectorValue]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      updateWebsiteUrl(window.location.origin);
    }
  }, []);

  return (
    <Container>
      <h3>
        {language.paragraph["dedication-of-rights-to-the-public-domain"].title}
      </h3>
      <p>
        <PreLine
          dangerouslySetInnerHTML={{
            __html:
              language.paragraph[
                "dedication-of-rights-to-the-public-domain"
              ].text(WEBSITE_URL),
          }}
        />
        <br />
        <br />
        <h4>{language.paragraph["requirements-and-registration"].title}</h4>
        {language.paragraph["requirements-and-registration"].text}
        <br />
        <br />
        <h4>{language.paragraph["uploading-content"].title}</h4>
        <PreLine
          dangerouslySetInnerHTML={{
            __html: language.paragraph["uploading-content"].text,
          }}
        />
        <br />
        <br />
        <h4>{language.paragraph["use-of-the-service"].title}</h4>
        <PreLine>{language.paragraph["use-of-the-service"].text}</PreLine>
        <br />
        <br />
        <h4>{language.paragraph["notice-and-takedown-policy"].title}</h4>
        <PreLine
          dangerouslySetInnerHTML={{
            __html: language.paragraph["notice-and-takedown-policy"].text,
          }}
        />
        <br />
        <br />
        <h4>{language.paragraph["termination"].title}</h4>
        {language.paragraph["termination"].text}
        <br />
        <br />
        <h4>
          {language.paragraph["indemnification-for-breach-of-terms"].title}
        </h4>
        {language.paragraph["indemnification-for-breach-of-terms"].text}
        <br />
        <br />
        <h4>{language.paragraph["warranty-and-liability"].title}</h4>
        {language.paragraph["warranty-and-liability"].text}
      </p>
    </Container>
  );
};

export default RightsToPublicDomain;
